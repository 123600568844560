import { Dispatch, useEffect } from 'react'
import { UserInterests } from '../../Types';
import InterestCard from './InterestCard';
import { InterestActions } from './reducer';

interface InterestsProps {
  interests: Array<UserInterests>,
  dispatch: Dispatch<InterestActions>,
}

const Interests = ({ interests, dispatch }:InterestsProps) => {

  useEffect(() => {
    console.log(interests);
  }, [interests])
  return (
    <div className="interests">
              {window.config.categories.map((props, index) => {
            return (
              <InterestCard
                {...props}
                key={props.id}
                allInterests={window.config.categories}
                interests={interests}
                dispatch={dispatch}
              />
            );
          })}
    </div>
  )
}

export default Interests