import { useEffect, useState } from "react";

// Lib
import parse from "html-react-parser";

// Utils
import { handleOverrideClassName, handleJsBridgeForAppVersion } from "../../utils/CommonFunctions";

const Version = ({ item }) => {
  const [appVersionObj, setappVersionObj] = useState({ version: "", build: "" });

  //  Call the Js Bridge on Component Load
  useEffect(() => {
    const fetchAppVersion = async () => {
      const versionObj = await Promise.resolve(handleJsBridgeForAppVersion());

      if (versionObj && Object.keys(versionObj).length !== 0) {
        setappVersionObj(versionObj);
      }
    };

    fetchAppVersion();
  }, [appVersionObj.version, appVersionObj.build]);

  return (
    <div className={`app-version-component ${handleOverrideClassName(item.classname)}`}>
      <div className="content-section">
        {/* App Version Text */}

        {item.title && appVersionObj?.version && (
          <div className="app-version-row">
            <span className={`header-text ${handleOverrideClassName(item.headerTextClassname)}`}>
              {" "}
              {parse(item.title)}
            </span>
            {appVersionObj?.version && (
              <span className="app-version">{`${appVersionObj?.version}`}</span>
            )}

            {appVersionObj?.build && (
              <span className="build-version">{`(${appVersionObj?.build})`}</span>
            )}
          </div>
        )}

        {/* Description */}

        {item.copyright && (
          <p className={`bodyText ${handleOverrideClassName(item.bodyTextClassname)}`}>
            {parse(item.copyright)}
          </p>
        )}
      </div>

      {item.image && (
        <div
          className={`img-card ${handleOverrideClassName(item.imgClassname)}`}
          style={{ backgroundImage: item.image.path && `url(${item.image.path})` }}
        />
      )}
    </div>
  );
};

export default Version;
