import { useTranslation } from 'react-i18next';
import OnboardingPage, { Description, Title } from '../UI/OnboardingPage.wrapper'

const OnboardingIntro = () => {
  const [t] = useTranslation("translations");


  return (
    <OnboardingPage withSkip>
      <img
        className="onboarding__img"
        src="/assets/onboarding/intro.png"
        alt={t("Onboarding.Intro.page_header")}
      />
      <Title>{t("Onboarding.Intro.page_header")}</Title>
      <Description>{t("Onboarding.Intro.page_description")}</Description>
    </OnboardingPage>
  );
}

export default OnboardingIntro