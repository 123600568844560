import UserService from "../../../services/UserService";
import { useTranslation, Trans } from "react-i18next";
import './style.scss';

const SELECTED_OHT = (props: any) => {

    const [t] = useTranslation("translations");

    const location = UserService.getAttribute<"savedLocationOht">("savedLocationOht");

    if (location.type === "oht") {
        return (<div className="SELECTED_OHT_container">
            {location.secondary_image && <img src={location.secondary_image} alt={location.name}/>}
            <p><Trans i18nKey="SelectedOht.brought_to_you_by" values={{ name: location.name}} /></p>

            {location.url && (
                <a href={location.url} rel="noreferrer" target="_blank">{t("SelectedOht.learn_more")}</a>
            )}
        </div>)
    }else {
        return (<div className="SELECTED_OHT_container">
            {t("SelectedOht.generic_brought_to_you_by")}
            <a href="https://health.gov.on.ca/en/pro/programs/connectedcare/oht/default.aspx" rel="noreferrer" target="_blank">{t("SelectedOht.learn_more")}</a>
        </div>)
    }
  
};

export default SELECTED_OHT;

export const SelectedOHTConfig = () => {
  return [];
}