import React, { useState } from "react";
import { Oht } from "../Types";

interface OHTCardProps extends Oht {
  checkList: Array<Oht>;
  setCheckList: React.Dispatch<React.SetStateAction<Oht[]>>
}

const OhtSelectionCard = ({ checkList, setCheckList, ...oht }: OHTCardProps) => {

  const {description, id, image, name} = oht;


  const [isSelected, setIsSelected] = useState(checkList.some((item) => item.id === id))

  return (
    <div
      className={`ohtcard ${isSelected ? "ohtcard--selected" : ""}`}
      onClick={() => {

        if(checkList.some((item) => item.id === oht.id)) {
          setCheckList(checkList.filter((item) => item.id !== id))
          setIsSelected(false);
        }
        else if(checkList.length >= 10){
          return;
        }
        else {
          setCheckList([...checkList, oht ])
          setIsSelected(true);
        }

      }}
    >
      <img src={image} alt={description} className="ohtcard__image" />
      <div className="ohtcard__textcontainer">
        <p className="ohtcard__name">{name}</p>
        <p className="ohtcard__description">{description}</p>
      </div>
    </div>
  );
};

export default OhtSelectionCard;
