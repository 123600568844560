import { useTranslation } from "react-i18next";

interface ButtonProps {
    isDisabled?: boolean;
    onClickOverride?: any;
  }
  
  const Button = ({ isDisabled, onClickOverride }: ButtonProps) => {
    const [t] = useTranslation("translations");
  
    const handleDisabledStyles = isDisabled ? "ls__button--disabled" : "";
  
    return (
      <button
        disabled={isDisabled}
        className={`ls__button ${handleDisabledStyles}`}
        onClick={onClickOverride}
      >
        {t("LocationSelection.Save")}
      </button>
    );
  };

  export default Button