import { UserInterests, SubUserInterests } from "../../Types"

export type InterestActions =
 | { type: 'add_category', category: UserInterests }
 | { type: "remove_category", category_id: string}
 | {type: "add_subcategory", subcategory: SubUserInterests, category_id: string }
 | {type: "remove_subcategory", subcategory_id: string, category_id: string }

 export type InterestState = {
  interests: UserInterests[]
 }

 export function reducer({interests}: InterestState, action: InterestActions): InterestState {
  switch (action.type) {
    case 'add_category':
      const addCategory = () => {
        let newInterests = [...interests]
        if (interests.some((thisInterest) => thisInterest.id === action.category.id)){
          newInterests.splice(newInterests.findIndex(thisInterest => thisInterest.id === action.category.id), 1, action.category)
        } else {
          newInterests = [...newInterests, action.category]
        }

        return newInterests;
      }

      return { interests: addCategory()};
    case 'remove_category':
      const removeCategory = () => {
        let newInterests = [...interests]
        newInterests.splice(newInterests.findIndex(thisInterest => thisInterest.id === action.category_id), 1)

        return newInterests;
      }
      return {interests: removeCategory()}
    case 'add_subcategory':
      const addSubCategory = () => {
        let newInterests = [...interests]

        const doesThisCategoryExist = interests.some((thisInterest) => thisInterest.id === action.category_id);
        const categoryIndexFromId = interests.findIndex((thisInterest) => thisInterest.id === action.category_id)

        const doesThisSubcategoryExist = interests[categoryIndexFromId].subcategories.some((thisSubInterest) => thisSubInterest.id === action.subcategory.id )
        const subCategoryIndexFromId = interests[categoryIndexFromId].subcategories.findIndex((thisSubInterest) => thisSubInterest.id === action.subcategory.id)

        if (doesThisCategoryExist && doesThisSubcategoryExist){
          newInterests[categoryIndexFromId].subcategories.splice(subCategoryIndexFromId, 1, action.subcategory)
        } else {
          newInterests[categoryIndexFromId].subcategories.push(action.subcategory);
        }

        return newInterests;
      }
      return {interests: addSubCategory()}
    case 'remove_subcategory':
      const removeSubCategory = () => {
        let newInterests = [...interests]

        const categoryIndexFromId = interests.findIndex((thisInterest) => thisInterest.id === action.category_id)
        const subCategoryIndexFromId = interests[categoryIndexFromId].subcategories.findIndex((thisSubInterest) => thisSubInterest.id === action.subcategory_id)

          newInterests[categoryIndexFromId].subcategories.splice(subCategoryIndexFromId, 1)

        return newInterests;
      }
      return {interests: removeSubCategory()}
    default:
      throw new Error();
  }
}
