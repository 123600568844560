import React from "react";

// Utils
import { handleOverrideClassName, handleOverrideConfig } from "../../utils/CommonFunctions";

// Services
import i18n from "../../i18n";

const FC3 = ({
    item,
    theme
}) => {
    return (
        <div 
            className={`fc3 ${handleOverrideClassName(item.classname)}`}
            style={
                Object.assign({
                    boxShadow: handleOverrideConfig(theme.dropShadow, item.dropShadow) && '0px 3px 6px rgba(0, 0, 0, 0.23), 0px 3px 6px rgba(0, 0, 0, 0.16)',
                    background: (item.image &&  item.image.path) && `url(${item.image.path})`
                }, {})
            }
        >
            {/* Title text */}

            {
                item.title
                &&
                <h3 className={`header-text ${handleOverrideClassName(item.headerTextClassname)}`}>{i18n.t(item.title)}</h3>
            }

            {/* Body Text */}

            {
                item.description
                && 
                <p className={`bodyText ${handleOverrideClassName(item.bodyTextClassname)}`}>{i18n.t(item.description)}</p>
            }
        </div>
    );
}

export default FC3;
