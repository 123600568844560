interface ResultItemProps {
    postalCode: string;
    city: string;
    onClick?: React.MouseEventHandler<HTMLDivElement> | undefined
  }
  
  const ResultItem = ({ postalCode, city, onClick}: ResultItemProps) => {
    return (
      <div className="ls__result_item" onClick={onClick}>
        <div className="ls__result_icon">
          <span className={`icon-pin`}></span>
        </div>
          <div className="ls__result_locationbox">
            <p className="ls__result_postalcode">{postalCode}</p>
            <p className="ls__result_cityprovince">{city}</p>
          </div>
      </div>
    );
  };

  export default ResultItem