const addTile = (pageContent, allItems) => {
  const object = pageContent?.additional_tile?.tile;
  let newArray = allItems;

  switch (pageContent.additional_tile?.position) {
    case "First":
      newArray = [object, ...allItems];
      break;
    case "Last":
      newArray = [...allItems, object];
      break;
    default:
      newArray = allItems;
  }

  return newArray;
};

export default addTile;
