import { postMessage } from "./NativePostMessage";

const ShowTopBar = (visibility: boolean) => {
  postMessage("setTopBarVisible", visibility);
};

const ShowBottomBar = (visibility: boolean) => {
  postMessage("setBottomBarVisible", visibility);
};

export { ShowBottomBar, ShowTopBar };