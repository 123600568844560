import { TFunctionResult } from "i18next";

interface Props {
  children?: React.ReactNode & TFunctionResult;
}

export const ErrorPage = ({ children }: Props) => {
  return (
    <main className="errorpage">
      <div className="errorpage__container">{children}</div>
    </main>
  );
};

interface IconProps extends Props {
  icon_type: "exclamation" | "network";
}

export const ErrorIcon = ({ icon_type }: IconProps) => {
  return (
    <div>
      <div className="errorpage__icon">
      <span className={`icon-${icon_type}`}></span>
      </div>
    </div>
  );
};

export const Title = ({ children }: Props) => {
  return <h1>{children}</h1>;
};

export const Description = ({ children }: Props) => {
  return <p className="errorpage__description">{children}</p>;
};

interface ButtonProps extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  children?: React.ReactNode & TFunctionResult;
  onClick?: React.MouseEventHandler<HTMLButtonElement>;
}

export const Button = ({ children, onClick, ...rest }: ButtonProps) => {
  const isOnClickNull = onClick === undefined;

  const onClickFallback = () => {
    window.location.reload();
  };

  return (
    <button
      {...rest}
      onClick={isOnClickNull ? onClickFallback : onClick}
      className="errorpage__btn"
    >
      {children}
    </button>
  );
};
