import AnalyticsService from "analytics-web";

export const handleAnalyticsTrack = (page: string, type: string, action: string) => {
    AnalyticsService.track(type, {
        context: 'Listing Page',
        page,
        actions: action,
        url: window.location.hostname + window.location.pathname,
    })
}

