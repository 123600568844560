import { useState } from "react";

type OnlineStatusType = "offline" | "online";

/**
 * A service that checks if a user is online.
 * @returns onlineStatus: string, "online" or "offline" respective to user's connection to server.
 */
const useOnlineStatus = () => {
    const initialOnlineStatus = window.navigator.onLine ? "online" : "offline";

  const [onlineStatus, setOnlineStatus] = useState<OnlineStatusType>(initialOnlineStatus);


  window.addEventListener("offline", function (e) {
    setOnlineStatus("offline");
  });

  window.addEventListener("online", function (e) {
    setOnlineStatus("online");
  });

  return {onlineStatus}
};

export default useOnlineStatus;
