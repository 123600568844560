import { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { ShowBottomBar, ShowTopBar } from "../../../../services/ToggleNativeBar";
import UserService from "../../../../services/UserService";
import Button from "../Components/Button";
import OhtManager from "../Managers/OhtManager";
import { Oht } from "../Types";

const SelectOHT = () => {
  const [t] = useTranslation("translations");
  const history = useNavigate();

  useEffect(() => {
    ShowTopBar(true);
    ShowBottomBar(false);
  }, []);

  const [items, setItems] = useState<Array<Oht>>(UserService.getAttribute("ohts"));
  const [isDisabled] = useState<boolean>(false);

  useEffect(() => {
    console.log(items);
  }, [items])

  const handleOnSaveClick = () => {
    // goes back
    history(-1);

    UserService.setAttribute("ohts", items);
  };

  return (
    <div className="ls__container">
    <div className="ls__page ls__page--select_oht">
      <Helmet>
        <title>{t("LocationSelection.SelectOHT.page_title")}</title>
      </Helmet>
      <div className="ls__page__inner">
        <OhtManager items={items} setItems={setItems} />
      </div>
    </div>
      <div className="ls__bottom_button">
        <Button isDisabled={isDisabled} onClickOverride={handleOnSaveClick} />
      </div>
      </div>
  );
};

export default SelectOHT;
