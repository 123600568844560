import { useTranslation } from "react-i18next";

interface TextFieldProps {
    value: string | undefined;
    setValue: React.SetStateAction<any>;
    onChange?: React.ChangeEventHandler<HTMLInputElement> | undefined,
    isLocationFiltering?: boolean,
    onFocusInput?: React.SetStateAction<any>,
    onBlurInput?: React.SetStateAction<any>,
  }
  
  const TextField = ({ value, setValue, onChange, isLocationFiltering, onFocusInput, onBlurInput }: TextFieldProps) => {
    const [t] = useTranslation("translations");

 
  
    return (
      <div className={`textfield ${isLocationFiltering && 'fullwidth'}`}>
        <p className="textfield__label">
          {t("LocationSelection.AddLocation.location_label")}
        </p>
        <input
          type="text"
          className="textfield__input"
          placeholder={t("LocationSelection.AddLocation.location_placeholder")}
          value={value}
          onChange={onChange}
          onFocus={onFocusInput}
          onBlur={onBlurInput}
        />
      </div>
    );
  };

  export default TextField