import React from "react";

// Services
import i18n from "../../i18n";

// Images
import { ReactComponent as ArrowIcon } from "../../assets/icon_right_arrow.svg";

const buttonClassnames = {
  TEXT: "text-button",
  OUTLINED: "outlined-button",
  FILL: "fill-button",
};

const Button = ({
  buttonType,
  buttonClassname,
  buttonText,
  handleRedirect,
  showButtonIcon,
  buttonIconImage,
  buttonIconPosition,
  buttonIconClassname,
}) => {

  const iconStyle = {
    marginLeft: buttonIconPosition === "LEFT" ? 0 : undefined,
    marginRight: buttonIconPosition === "LEFT" ? 8 : undefined,
  };

  return (
    <button
      className={`action-button ${buttonClassnames[buttonType]} ${buttonClassname}`}
      onClick={handleRedirect}
      style={{ flexDirection: buttonIconPosition === "LEFT" ? "row-reverse" : "row" }}
    >
      {i18n.t(buttonText)}

      {/* Arrow Icon */}
      {showButtonIcon && (
        <>
          {buttonIconImage?.path ? (
            <img
              className={`icon-img ${buttonIconClassname}`}
              src={buttonIconImage.path}
              alt={buttonIconImage.alt_text}
              style={iconStyle}
            />
          ) : (
            <ArrowIcon className={`icon-img ${buttonIconClassname}`} />
          )}
        </>
      )}
    </button>
  );
};

export default Button;
