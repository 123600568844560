import React from "react";

// Libraries
import { NavLink } from "react-router-dom";

// Services
import i18n from "../../i18n";

// Utils
import { handleOverrideClassName, handleRedirect } from "../../utils/CommonFunctions";

const SidebarMenu = ({ menuContent, history, pageName }) => {
    
    return (
        <nav className="sidebar" aria-label="sidebar">

            {
                menuContent.links && menuContent.links.length && menuContent.links.map((link, i) => (
                    <NavLink 
                        key={i} 
                        className={isActive =>
                            `nav-item ${handleOverrideClassName(link.linkClassname)}` + (!isActive ? " unselected" : "")
                        }
                        to={""} 
                        onClick={() => handleRedirect(link.link_action, history, link.link_text, pageName)}
                    >
                        {/* Icon Image */}

                        <div className="side-icon">
                            {
                                (link.icon_image && link.icon_image.path)
                                &&
                                <img className={`img-icon ${handleOverrideClassName(link.iconClassname)}`} src={link.icon_image.path} alt={link.icon_image.alt_text} />
                            }
                        </div>

                        {/* Link Text */}

                        {
                            link.link_text
                            &&
                            <p className={`link-text ${handleOverrideClassName(link.linkTextClassname)}`}>{i18n.t(link.link_text)}</p>
                        }
                    </NavLink>
                ))
            }

            {/* Copyright text */}

            {
                menuContent.copyright
                &&
                <p className="copyright">
                    {i18n.t(menuContent.copyright)}
                </p>
            }
        </nav>
    );
}

export default SidebarMenu;
