import React from "react";

// Utils
import { handleOverrideClassName, handleOverrideConfig } from "../../utils/CommonFunctions";

// Services
import i18n from "../../i18n";

const IC2 = ({ 
    item,
    theme
}) => {
    
    return (
        <div 
            className={`ic2 ${handleOverrideClassName(item.classname)}`} 
            style={
                Object.assign({
                    boxShadow: handleOverrideConfig(theme.dropShadow, item.dropShadow) && '0px 3px 6px rgba(0, 0, 0, 0.23), 0px 3px 6px rgba(0, 0, 0, 0.16)',
                    justifyContent: handleOverrideConfig(theme.showImage, item.showImage) ? 'unset' :  'center',
                    flexDirection: item.imagePosition === "TOP" ? "column-reverse" : "column",
                }, {})
            }
        >   
        
            {
                handleOverrideConfig(theme.showImage, item.showImage)
                &&
                <div 
                    className={`img-card ${handleOverrideClassName(item.imgClassname)}`}
                    style={Object.assign({
                        borderRadius: (item.imagePosition && "LEFT") && "0 0 8px 8px"
                    }, {})}
                >
                    {
                        (item.image && item.image.path)
                        &&
                        <img src={item.image.path} alt={item.image.alt_text} />
                    }
                </div>
            }

            {
                item.description
                &&
                <p className={`bodyText ${handleOverrideClassName(item.bodyTextClassname)}`}>{i18n.t(item.description)}</p>
            }
        </div>
    );
}

export default IC2;
