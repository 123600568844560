import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { importAllPages } from "../importPages";
import { handleRedirect } from "../../../utils/CommonFunctions"
import { useDidUpdateEffect } from "../../../hooks/useUpdateEffect";
import i18n from "../../../i18n";

interface ProfileI {
  title: string;
  links: Array<LinksI>
}

interface LinksI {
  pageType?: string;
  text: string;
  url?: string;
}

const PROFILE_PREFERENCES = (props: any) => {
  const [data, setData] = useState<Array<ProfileI>>([]);
  const [pageName] = useState<string>('');
  const history = useNavigate();

  useDidUpdateEffect(() => {

    console.log(props.item);
    if (props.item) {
      // const filterData = Object.entries?.(props.item.menu).reduce((acc: any, [key, values]: any) => {
        
      //   if (values?.menu) {
      //     const testingReduce = values.content_items.reduce((acc1: any, curr1: any) => [...acc1, ...((curr1.items.filter((testing: any) => testing.itemType === 'PROFILE_PREFERENCES'))?.[0]?.menu || [])], [])
      //     if (testingReduce.length > 0) {
      //       acc = [...acc, key, testingReduce]
      //     }
      //   }
      //   return acc
      // }, [])
      // setPageName(filterData[0] || '');
      setData(props.item.menu || []);
    }
  }, []);

  return (
    <div className="profile_preferences_container">
      {
        data.length > 0 ? data.map((item: ProfileI) => (
          <>
            {item.title && <h2>{i18n.t(item.title) as string}</h2>}
            {
              item.links.length > 0 ? item.links.map((link: LinksI) => (
                <button onClick={() =>  handleRedirect({ type: link.url ? 'URL' : 'IN_APP', url: link.url || '', destinationPageId: link.pageType || '' }, history, i18n.t(link.text), pageName)} >{i18n.t(link.text) as string}</button>
              )) : null
            }
          </>
        )) : null
      }
    </div>
  )
};

export default PROFILE_PREFERENCES;

export const ProfileConfig = () => {
  return importAllPages(PROFILE_PREFERENCES, require.context("./Pages", true, /\.(tsx|ts|js)$/, "sync"))
}
