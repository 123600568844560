import { useEffect, useRef } from "react";

// Lib
import { BrowserRouter as Router, Route } from "react-router-dom";
import { Helmet } from "react-helmet";
import axios, { AxiosError } from "axios";
import AnalyticsService from "analytics-web";
import SlideRoutes from "react-slide-routes";
import { useErrorHandler } from "react-error-boundary";

// Components
import ListingPage from "./components/ListingPage";
import StandardLayout from "./components/Layout/StandardLayout";

// Utils
import i18n from "./i18n";

// Services
import useOnlineStatus from "./hooks/useOnlineStatus";

// Type Definitions
import default_theme from "../public/config/defaultpage.json";
import nygh_theme from "../public/config/nygh.json";
import { useApp } from "./context/AppContext";
import useManageLocalizationFailure from "./hooks/useManageLocalizationFailure";
import useRouteRegistrationService from "./hooks/useRouteRegistrationService";
import { useDidUpdateEffect } from "./hooks/useUpdateEffect";
import { LocationSelectionConfig } from "./components/StaticWidgets/LOCATION_SELECTION";
import { SearchBarConfig } from "./components/StaticWidgets/SEARCH_BAR";
import { ProfileConfig } from "./components/StaticWidgets/PROFILE_PREFERENCES";
import UserService from "./services/UserService";
import { ShowBottomBar, ShowTopBar } from "./services/ToggleNativeBar";
import OnboardingConfig from "./components/Onboarding";
import { initializeNativePostMessageService } from "./services/NativePostMessage";
import SelectYourHealthPlanEmail from "./SelectYourHealthPlanEmail";

export type ThemeProps = typeof default_theme & typeof nygh_theme;

declare global {
  interface Window {
    config: any;
    webkit: any;
    APP_COMPONENT: any;
    GLOBAL_PATH: any;
    Android: any;
  }
}
const App = () => {
  const {
    isAppReady,
    setIsAppReady,
    isAnalyticsInitialized,
    setIsAnalyticsInitialized,
    staticWidgets,
    addConfig,
  } = useApp();
  const handleError = useErrorHandler();
  const { onlineStatus } = useOnlineStatus();

  // Registration Service
  const { isRouteRegistrationServiceReady, setIsRouteRegistrationServiceReady } =
    useRouteRegistrationService();

  // Mount all static widget configs here
  useDidUpdateEffect(() => {
    if (isRouteRegistrationServiceReady) {
      addConfig(LocationSelectionConfig());
      addConfig(SearchBarConfig());
      addConfig(ProfileConfig());
      window.config.onboarding && addConfig(OnboardingConfig()); // eslint-disable-line
    }
  }, [isRouteRegistrationServiceReady]);
  // <--- Handle Errors --->

  // Axios Error Handler
  axios.interceptors.response.use(
    (res) => res,
    (error: AxiosError) =>
      handleError({
        message: error.message,
        error_code: "ERROR_API_FAILURE",
        error_response: error.response,
      })
  );

  // Manage Localization Failure
  useManageLocalizationFailure();

  // OnlineStatus Handler
  useEffect(() => {
    if (onlineStatus === "offline") {
      handleError({
        message: "Network Connection Lost",
        error_code: "ERROR_NO_NETWORK",
      });
    }
  }, [onlineStatus, handleError]);

  // Ref for sidebar
  const childRef = useRef({});
  window.APP_COMPONENT = childRef;

  useEffect(() => {
    const startupActions = () => {
      const startup = [];

      const location_args = window.location.host.split(".");
      const host_prefix = location_args[0];

      startup.push(
        new Promise((resolve, reject) => {
          axios.get("./config/config.json").then((res) => {
            window.config = res.data;

            const headID = document.getElementsByTagName("head")[0];
            const link = document.createElement("link");
            link.type = "text/css";
            link.rel = "stylesheet";

            headID.appendChild(link);

            if (window.config.remote_prefix) {
              link.href = `${window.config.remote_prefix}/${host_prefix}/whitelabel.css`;
            } else if (window.config.whitelabel) {
              link.href = window.config.whitelabel;
            }

            resolve(res.data);
          });
        })
      );

      return Promise.all(startup)
        .then(async (res: Array<any>) => {
          // Get the Content for Listing Page
          let content;

          const url = window.config.remote_prefix
            ? `${window.config.remote_prefix}/${host_prefix}/content.json`
            : res?.[0]?.content_url;

          if (url) {
            content = await axios.get(url);
          }

          window.config = { ...window.config, ...content.data };
          initializeNativePostMessageService();

          setIsAppReady(true);
        })
        .then(() => {
          setIsRouteRegistrationServiceReady(true);
        });
    };

    startupActions();
  }, [setIsAppReady, handleError, setIsRouteRegistrationServiceReady]);

  // Analytics
  if (window.config && !isAnalyticsInitialized) {
    AnalyticsService.initialize(window.config.analytics_config.alias, {
      instrumentationKey: window.config.analytics_config.instrumentationKey,
      disableAjaxTracking: true,
    });

    setIsAnalyticsInitialized(true);
  }

  if (!isAppReady) {
    return <div />;
  }

  const InitialPage = () => {
    const { skipOnboarding } = UserService.getAttribute("userPreferences");
    if ((skipOnboarding === undefined || skipOnboarding === false) && window.config.onboarding) {
      const InitialOnboardingComponent = OnboardingConfig().components[0].Component;
      return <InitialOnboardingComponent />;
    }
    ShowTopBar(true);
    ShowBottomBar(true);
    return (
      <StandardLayout ref={childRef}>
        <ListingPage />
      </StandardLayout>
    );
  };

  const noscriptText = i18n.t("noscript");

  return (
    <div className="App">
      <Helmet>
        <meta charSet={i18n.t("charset")} />
        <link href={i18n.t("favicon")} />
        <link rel="shortcut icon" href={window.config.favicon} />
        <noscript>{noscriptText.toString()}</noscript>
      </Helmet>

      <div className="app-wrapper">
        <Router>
          <main>
            <SlideRoutes timing="ease-in-out" duration={300}>
              <Route path={window.GLOBAL_PATH} element={<InitialPage />} />
              <Route
                path={`${window.GLOBAL_PATH}:route_id`}
                element={
                  <StandardLayout ref={childRef}>
                    <ListingPage />
                  </StandardLayout>
                }
              />
              {isRouteRegistrationServiceReady &&
                staticWidgets.map((Component) => (
                  <Route
                    key={Component.name}
                    path={`${window.GLOBAL_PATH}/${Component.name}`}
                    element={
                      // @ts-ignore
                      <Component />
                    }
                  />
                ))}
              <Route 
                path={`${window.GLOBAL_PATH}/select-your-health-plan-email`}
                element={
                  <StandardLayout ref={childRef}>
                    <SelectYourHealthPlanEmail />
                  </StandardLayout>
                }
              />
            </SlideRoutes>
          </main>
        </Router>
      </div>
    </div>
  );
};

export default App;
