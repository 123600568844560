import React from "react";

// Localization
import { useTranslation } from 'react-i18next';

const NoResultBlock = ({ queryString, isFilteringActive }: { queryString?: string, isFilteringActive?: boolean }) => {
  const [t] = useTranslation("translations");

  const emptyMessage = isFilteringActive ? 'SearchWidget.no_results_after_filtering_error' : 'SearchWidget.no_results_error';
  return (
    <p 
      className="no-results-container" 
      dangerouslySetInnerHTML={{ __html: t(emptyMessage, { queryString }) }}
    />
  );
};

export default NoResultBlock;
 