
interface props {
  getSuggestedSearches(queryString: string): any;
  handleGroupingResultsByCategory(data: Array<any>, groupItemsBy: string): any;
  handleSearchingLogic(searchable_keys: any, searchableContent: Array<any>, queryString: string): any;
  handleRecentSearchesLogic(recentSearches: Array<string>, queryString: string, data: any): any;
  handleUnGroupingResultsByCategory(data: any): any;
  handleFilteringLogic(searchableContent: Array<any>, filters: any): any;
}

let SearchServices: Partial<props> = {};

// Service to get the Suggested Searches For QueryString

SearchServices.getSuggestedSearches = function (queryString) {
  return new Promise((resolve, reject) => {

    let tempSuggestedSearches = [];

    try {
      
      // TODO Add Api search here 
      tempSuggestedSearches = ["counselling", "counselling crisis", "counselling services"];

      resolve(tempSuggestedSearches);
    } catch (error) {
      return reject("SearchWidget.suggested_searches_error")
    }
  });
};

// Search Logic

SearchServices.handleSearchingLogic = function (searchable_keys, searchableContent, queryString) {
  return new Promise<void>((resolve, reject) => {
    let result: any = [];
    let tempSearchableKeys = searchable_keys || [];

    if (searchableContent?.length) {
      for (let content of searchableContent) {
        for(let key in content) { 

          // 1.1 Search by config provided keys
          if (tempSearchableKeys?.length && tempSearchableKeys?.includes(key))  {

            // 1.2 If the Key value is  not array of strings
            if (!Array.isArray(content[key])) {
              if (content[key].toLowerCase().indexOf(queryString.trim().toLowerCase()) !== -1) {
                result.push(content);
                break;
              }
            } else {

              // 1.3 If the Key value is an array of strings
              for (let item of content[key]) { 
                if (item.toLowerCase().indexOf(queryString.trim().toLowerCase()) !== -1) {
                  result.push(content);
                  break;
                }
              }
            }
          }   
        }
      }
    }

    resolve(result);
  })
};

// Grouping Search Results

SearchServices.handleGroupingResultsByCategory = function (data: Array<any>, groupItemsBy: string) {
  return (
    new Promise((resolve, reject) => {
      if (data.length) {
        data = data.reduce((r: any, a: any) => {
            a[groupItemsBy].forEach(item => {
              r[item] = r[item] || [];
              r[item].push(a);  
            })

            return r;
        }, Object.create(null));
      }

      resolve(data);
    })
  )
};

// UnGrouping Search Results

SearchServices.handleUnGroupingResultsByCategory = function (data: any) {
  return (
    new Promise((resolve, reject) => {
      let result = []
      if (Object.keys(data).length !== 0) {
        Object.keys(data).map(item => (
          result = [...result, ...data[item]]
        ))
      }
      
      resolve(result);
    })
  )
};

// Recent Searches Logic

SearchServices.handleRecentSearchesLogic = function (recentSearches, queryString, data) {
  return (
    new Promise((resolve, reject) => {
      if (recentSearches.includes(queryString))  {
  
        recentSearches.splice(recentSearches.indexOf(queryString), 1);
        recentSearches.unshift(queryString);
  
      } else {
  
        // 3.1 Otherwise add new
        if (Object.keys(data).length !== 0) {
          recentSearches = [queryString, ...recentSearches];
        }
      }
   
      resolve(recentSearches);
    })
  )
};

// Filtering Logic

SearchServices.handleFilteringLogic = function (searchableContent, filters) {
  return new Promise<void>((resolve, reject) => {
    let result: any = [];

    if (searchableContent?.length) {
      // get all the keys for the filters

      const filterKeys = Object.keys(filters);

      result = searchableContent.filter(content => {

        return filterKeys.every(key => {
          if (!filters[key].length) {
            return true;
          }
        
          if (content[key]) {
            return filters[key].every((item: any) => content[key].includes(item));
          }

          return false;
        });
      });
    }

    resolve(result);
  })
};

export default SearchServices;
