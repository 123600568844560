import React, { useEffect, useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import UserService from "../../../../services/UserService";
import LocationManager from "../Managers/LocationManager";
import { Position } from "../Types";

import queryString from "query-string";

import { Location } from "../Types";
import useRequestSpinner from "../../../../hooks/useRequestSpinner";
import FormatAddressService from "../../../../services/FormatAddressService";
import SpinnerImage from "../Components/SpinnerImage";
import { useTranslation } from "react-i18next";
import { Helmet } from "react-helmet";
import Button from "../Components/Button";
import { ShowBottomBar, ShowTopBar } from "../../../../services/ToggleNativeBar";

const EditLocation = () => {
  const [address, setAddress] = useState<string>("");
  const [position, setPosition] = useState<Position | undefined>();
  const [distance, setDistance] = useState<number>(20);
  const [isValidAddress, setIsValidAddress] = useState(true);

  const { handleRequestWithSpinner, requestLoading } = useRequestSpinner();

  useEffect(() => {
    ShowTopBar(true);
    ShowBottomBar(false);
  }, []);

  const [t] = useTranslation("translations");
  const history = useNavigate();
  const { hash } = useLocation();
  const { id: idHash } = queryString.parse(hash);

  useEffect(() => {
    const locationById = UserService.getAttribute(
      "locations"
    ).find(({ id }) => id === (idHash as string));

    if (locationById !== undefined) {
      const { address, distance, position } = locationById;
      setAddress(address.formattedAddress);
      setPosition(position);
      setDistance(distance);
    }
  }, [idHash]);

  const handleOnSaveClick = (
    city: string,
    postal_code: string,
    formatted_address: string
  ) => {
    // goes back
    history(-1);

    if (!position) {
      return;
    }

    const locations = UserService.getAttribute("locations");

    const currentLocationSelected = locations.find(
      ({ id }) => id === (idHash as string)
    );

    const updatedLocations = UserService.getAttribute(
      "locations"
    ).map((location) => {
      if (location.id === currentLocationSelected?.id) {
        return {
          id: location.id,
          distance,
          address: {
            city: city,
            postalcode: postal_code,
            formattedAddress: formatted_address,
          },
          position,
          type: "location",
        } as Location;
      } else {
        return location;
      }
    });

    UserService.setAttribute("locations", updatedLocations);
  };
  return (
    <div className="ls__page">
      <Helmet>
        <title>{t("LocationSelection.AddLocation.page_title")}</title>
      </Helmet>
      <LocationManager
        address={address}
        distance={distance}
        position={position}
        isValidAddress={isValidAddress}
        setAddress={setAddress}
        setDistance={setDistance}
        setPosition={setPosition}
        setIsValidAddress={setIsValidAddress}
      />
      {<SpinnerImage loading={requestLoading} />}
      <div className="ls__bottom_button">
        <Button
          isDisabled={!isValidAddress}
          onClickOverride={() =>
            handleRequestWithSpinner(FormatAddressService(address), (res) =>
              handleOnSaveClick(
                res?.city || "",
                res?.postal_code || "",
                res?.formatted_address || ""
              )
            )}
        />
      </div>
    </div>
  );
};

export default EditLocation;
