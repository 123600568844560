import React from "react";
import SVG from "react-inlinesvg";

import bigPin from "../../assets/location_selection/big_pin.svg";
import rightArrow from "../../assets/icon_right_arrow.svg";
import downArrow from "../../assets/icon_chevron_down.svg"

// Preferences
import mental from "../../assets/onboarding/mental.svg"
import news from "../../assets/onboarding/news.svg"
import wellness from "../../assets/onboarding/wellness.svg"

export enum IconEnum {
  big_pin = "big_pin",
  right_arrow = "right_arrow",
  mental = "mental",
  news = "news",
  wellness = "wellness",
  down_arrow = "down_arrow"
}

interface IconProps {
  iconName: keyof typeof IconEnum;
  className?: string;
  fill?: string;
  stroke?: string;
}

const Icon = ({ iconName, className = "", ...rest }: IconProps) => {
  let src = "";

  switch (iconName) {
    case "big_pin":
      src = bigPin
      break;
    case "right_arrow":
      src = rightArrow
      break;
    case "mental":
      src = mental
      break;
    case "news":
      src = news
      break;
    case "wellness":
      src = wellness
      break;
    case "down_arrow":
      src = downArrow
      break;
  }

  return <span className={`${className}`}>{<SVG src={src} {...rest} />}</span>;
};

export default Icon;
