import React, { useState, useEffect } from "react";
import axios from "axios";
import dateFormat from "dateformat";

// Components
import Calendar from "react-calendar";

// Helper Functions
import {
  startAuthorization,
  getAccessTokens,
} from "../../services/FpxApiHelpers";

// Utils
import {
  handleOverrideClassName,
  handleOverrideConfig,
} from "../../utils/CommonFunctions";

// Services
import i18n from "../../i18n";

const CarePlanWidget = ({ item, theme, history, pageName }) => {
  const [date, setDate] = useState(new Date());
  const [isDataArray, setDataArray] = useState([]);
  const [isAuthorized, setAuthorized] = useState(false);

  const onDateChange = (newDate) => {
    setDate(newDate);
  };

  let authClass = {};

  if (isAuthorized) {
    authClass = {
      content: "content-section view-content",
      buttonClass: "careplan-button view-btn",
      buttonText: i18n.t(item.buttonText2),
      click: "",
    };
  } else {
    authClass = {
      content: "content-section auth-content",
      buttonClass: "careplan-button auth-btn",
      buttonText: i18n.t(item.buttonText1),
      click: () => startAuthorization(item.authUrl, item.ticket, item.clientId),
    };
  }

  useEffect(() => {
    const params = new Proxy(new URLSearchParams(window.location.search), {
      get: (searchParams, prop) => searchParams.get(prop),
    });
    // Get the value of "some_key" in eg "https://example.com/?some_key=some_value"
    let ticketValue = params.ticket; // "some_value"

    if (!ticketValue) {
      return;
    }

    getAccessTokens(item.authUrl, ticketValue, item.clientId, item.secret).then(
      (accessTokens) => {
        console.log(accessTokens);
        let config = {
          headers: {
            Authorization:
              "Bearer " + accessTokens.access_tokens[0].access_token,
          },
        };

        axios
          .get(accessTokens.access_tokens[0].resource_location, config)
          .then((res) => {
            console.log(res);
            const data = res.data.appointments;
            localStorage.setItem("calendar-data", JSON.stringify(data));
            console.log(data);
            let dataToArray = data.map((obj, key) => {
              return {
                key,
                date: obj.time,
                title: obj.title,
                type: obj.type,
              };
            });
            setDataArray(dataToArray);
          });
        setAuthorized(true);
      }
    );
  });

  const setClass = (day) => {
    const dateobj =
      isDataArray &&
      isDataArray.find((x) => {
        if (dateFormat(day, "dd-mm-yyyy") === x.date) {
          return x.type;
        } else {
          return "";
        }
      });
    return dateobj ? dateobj.type : "";
  };

  const tileClass = (date) => {
    if (isAuthorized) {
      return ({ date }) => `calendar-tile ${setClass(date)}`;
    } else {
      return "calendar-tile";
    }
  };

  return (
    <div
      className={`careplan-widget ${handleOverrideClassName(item.classname)}`}
      style={Object.assign(
        {
          boxShadow:
            handleOverrideConfig(theme.dropShadow, item.dropShadow) &&
            "0px 3px 6px rgba(0, 0, 0, 0.23), 0px 3px 6px rgba(0, 0, 0, 0.16)",
        },
        {}
      )}
    >
      {/* <CalendarWidget /> */}
      <Calendar
        onChange={onDateChange}
        value={date}
        tileClassName={tileClass(date)}
        showNeighboringMonth={false}
        minDetail="month"
        calendarType="US"
      />
      <div className={authClass.content}>
        {!isAuthorized && (
          <div>
            <h3>{i18n.t(item.prompt)}</h3>
          </div>
        )}

        {/* Title text */}

        {item.title && isAuthorized && (
          <h3
            className={`header-text ${handleOverrideClassName(
              item.headerTextClassname
            )}`}
          >
            {i18n.t(item.title)}
          </h3>
        )}

        {/* Alerts */}
        {isAuthorized && (
          <div className="notif">
            {isDataArray.map((obj, key) => {
              if (obj.date === dateFormat(date, "dd-mm-yyyy")) {
                const classType = `${obj.type}-alert alert`;
                return (
                  <p key={key} className={classType}>
                    {obj.title}
                  </p>
                );
              } else {
                return "";
              }
            })}
          </div>
        )}

        <div
          className="button-wrapper"
          style={{
            justifyContent:
              handleOverrideConfig(
                theme.buttonAlignMent,
                item.buttonAlignMent
              ) === "left"
                ? "flex-start"
                : handleOverrideConfig(
                    theme.buttonAlignMent,
                    item.buttonAlignMent
                  ) === "right"
                ? "flex-end"
                : "center",
          }}
        >
          <button className={authClass.buttonClass} onClick={authClass.click}>
            {authClass.buttonText}
          </button>
        </div>
      </div>
    </div>
  );
};

export default CarePlanWidget;
