import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import UserService from "../../../../services/UserService";

import { uuid } from "uuidv4";
import { Position } from "../Types";
import LocationManager from "../Managers/LocationManager";
import useRequestSpinner from "../../../../hooks/useRequestSpinner";
import SpinnerImage from "../Components/SpinnerImage";
import FormatAddressService from "../../../../services/FormatAddressService";
import { Helmet } from "react-helmet";
import { useTranslation } from "react-i18next";
import Button from "../Components/Button";
import { ShowBottomBar, ShowTopBar } from "../../../../services/ToggleNativeBar";

const AddLocation = () => {
  const [address, setAddress] = useState<string>("");
  const [position, setPosition] = useState<Position | undefined>();
  const [distance, setDistance] = useState<number>(20);
  const [isValidAddress, setIsValidAddress] = useState(false);

  useEffect(() => {
    ShowTopBar(true);
    ShowBottomBar(false);
  }, []);
  
  const { handleRequestWithSpinner, requestLoading } = useRequestSpinner();
  
  const history = useNavigate();
  const [t] = useTranslation("translations");


  const handleOnSaveClick = async (
    city: string,
    postal_code: string,
    formatted_address: string
  ) => {
    history(-1);

    if (!position) {
      return "error";
    }
    
    let allLocations = UserService.getAttribute("locations");

    allLocations = [
      {
        id: uuid(),
        distance,
        address: {
          city: city,
          postalcode: postal_code,
          formattedAddress: formatted_address,
        },
        position,
        type: "location",
      },
      ...allLocations,
    ];

    UserService.setAttribute("locations", allLocations);

    return "done";
  };

  return (
    <div className="ls__page">
      <Helmet>
        <title>{t("LocationSelection.AddLocation.page_title")}</title>
      </Helmet>
      <LocationManager
        address={address}
        distance={distance}
        position={position}
        isValidAddress={isValidAddress}
        setAddress={setAddress}
        setDistance={setDistance}
        setPosition={setPosition}
        setIsValidAddress={setIsValidAddress}
      />
      {<SpinnerImage loading={requestLoading} />}
      <div className="ls__bottom_button">
        <Button
          isDisabled={!isValidAddress}
          onClickOverride={() =>
            handleRequestWithSpinner(FormatAddressService(address), (res) =>
              handleOnSaveClick(
                res?.city || "",
                res?.postal_code || "",
                res?.formatted_address || ""
              )
            )}
        />
      </div>
    </div>
  );
};

export default AddLocation;
