import React from 'react'
import { useNavigate } from "react-router";

const Location = () => {

  const history = useNavigate();

  return (
    <div>
      <h1>Internal Page</h1>
      <div onClick={() => { history('/profile') }}>
        Back to Profile
      </div>
    </div>
  )
}

export default Location