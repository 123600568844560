import { UserPreferences } from "../components/Onboarding/Types";
import {
  Oht,
  Location,
} from "../components/StaticWidgets/LOCATION_SELECTION/Types";

type UserServiceKeys = "locations" | "ohts" | "savedLocationOht" | "recent_searches" | "userPreferences"

type UserServiceValues<T> = 
  /* Widgets */
  //Location Selection
  T extends "locations"
? Array<Location>
  : T extends "ohts"
  ? Array<Oht>
  : T extends "savedLocationOht"
  ? Location | Oht
  : T extends "userPreferences"
  ? UserPreferences
  : T extends "recent_searches"
  ? any
  : undefined;

const UserService = {
  setAttribute: function <T extends UserServiceKeys>(
    key: T,
    value: UserServiceValues<T>
  ) {
    localStorage.setItem(key, JSON.stringify(value));
  },
  getAttribute: function <T extends UserServiceKeys>(key: T): UserServiceValues<T> {
    return JSON.parse(localStorage.getItem(key) || "[]");
  },
  removeAttribute: function (key: UserServiceKeys) {
    localStorage.removeItem(key);
  },

};

export default UserService;
