import SliderUnstyled from '@mui/base/SliderUnstyled'

interface StepSliderProps {
    value: number | undefined;
    setValue: React.SetStateAction<any>;
  }

const StepSlider = ({
    value,
    setValue,
  }: StepSliderProps) => {
  
    const handleChange2 = (event: Event, value: number | number[], activeThumb: number) => {
      setValue(value);
    };
    
    return (
      <div className="ls__step_slider">
        <SliderUnstyled
          aria-label="Distance in KM"
          defaultValue={value}
          valueLabelDisplay="auto"
          step={5}
          marks
          value={value}
          onChange={handleChange2}
          min={5}
          max={50}
        />
      </div>
    );
  };
  
  export default StepSlider