import { ConfigType } from "../../context/AppContext";



export function importAllPages(indexComponent: any, callback: any) {
  function importAll(r: any) {
    return r.keys().map(r);
  }

  let ImportStaticWidgetComponents: Array<any> = importAll(callback);

  const config: ConfigType = {
    name: indexComponent.name,
    components: ImportStaticWidgetComponents.map((importedComponent) => {
      return {
        Component: importedComponent.default,
      };
    }),
  };

  return config;
}

export function importByPageList(indexComponent: (props: any) => JSX.Element, pageComponentList: Array<any>) {


  const config: ConfigType = {
    name: indexComponent.name,
    components: pageComponentList.map((importedComponent) => {
      return {
        Component: importedComponent,
      };
    }),
  };

  return config;
}
