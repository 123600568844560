import { JSONPath } from 'jsonpath-plus';

export const JSONQuery = (data: any, filter: string) =>  {
    return new Promise(async (resolve, reject) => {
        try {
            const result = JSONPath({
                json: data,
                path: filter,
                wrap: false,
            })

            resolve(result);
        } catch (err) {
            reject (err)
        }
    })
}
