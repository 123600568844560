import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next';
import UserService from '../../../services/UserService';
import OhtManager from '../../StaticWidgets/LOCATION_SELECTION/Managers/OhtManager';
import OnboardingPage, { Description, Title } from '../UI/OnboardingPage.wrapper'

const OnboardingOht = () => {
  const [items, setItems] = useState<Array<any>>(UserService.getAttribute("ohts"));
  const [isDisabled, setIsDisabled] = useState<boolean>(true);
  const [t] = useTranslation("translations");

  useEffect(() => {
    if(items.length > 0) {
      setIsDisabled(false);
    } else {
      setIsDisabled(true);
    }

  }, [items])

  const handleOnSaveClick = () => {
    UserService.setAttribute("ohts", items);
  };

  return (
    <OnboardingPage
      withSkip
      title="Set Your Preferences"
      withBack
      isNextBtnDisabled={isDisabled}
      nextBtnOnClick={() =>
        handleOnSaveClick()
      }
    >
      <Title>{t("Onboarding.Oht.page_header")}</Title>
      <Description>{t("Onboarding.Oht.page_description")}</Description>
        <OhtManager items={items} setItems={setItems} />
    </OnboardingPage>
  );
}


export default OnboardingOht