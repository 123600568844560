import React, {  } from "react";
import Icon from "../../../Reusable/Icon";
import SwipeDelete from "../../../Reusable/SwipeDelete";

interface ResultItemProps {
  secondary: string;
  main: string;
  distance: string;
  index: number;
  onDelete: (index: number) => void;
  onClick?: React.MouseEventHandler<HTMLDivElement> | undefined;
}

const LocationCard = ({
  main,
  secondary,
  distance,
  index,
  onDelete,
  onClick,
}: ResultItemProps) => {
  return (
    <SwipeDelete index={index} onDelete={onDelete} onClick={onClick}>
      <div className="ls__locationCard_left">
        <div className="ls__locationCard_iconContainer">
          <Icon iconName="big_pin" />
        </div>
      </div>
      <div className="ls__locationCard_right">
        <p className="ls__locationCard_cityprovince">{main}</p>
        <p className="ls__locationCard_postalcode">
          {secondary}, {distance}
        </p>
      </div>
    </SwipeDelete>
  );
};

export default LocationCard;