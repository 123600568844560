import React from "react";

// Svgs 
import {ReactComponent as SearchIcon} from '../../../../assets/icon_magnifier.svg';

const SearchHelperBlock = ({ data, headerText, handleUpdateQuerystring }: { data: Array<string>, headerText?: string, handleUpdateQuerystring?: (selectedString: string) => void }) => {
  return (
    <ul className="search-helper-container">
        <h3>{headerText}</h3>

        {
            data.map((item, i) => (
                <li 
                    key={i}
                    className="search-helper-item"
                >   
                    <button onClick={() => handleUpdateQuerystring(item)}>
                        <div className="search-icon-wrapper">
                            <SearchIcon className="search-icon" />
                        </div>

                        <span>{item}</span>
                    </button>
                </li>
            ))
        }  
      </ul>
  );
};

export default SearchHelperBlock;
 