import CircularProgress from "@material-ui/core/CircularProgress";
import Fade from "@material-ui/core/Fade";
import {  useLayoutEffect, useRef, useState } from "react";

interface SpinnerImageProps {
  loading?: boolean;
}

type TimeQueryType = "idle" | "progress" | "success";

const SpinnerImage = ({ loading = false }: SpinnerImageProps) => {
  const [query, setQuery] = useState<TimeQueryType>("idle");
  const timerRef = useRef(0);

  useLayoutEffect(
    () => () => {
      clearTimeout(timerRef.current);
    },
    [],
  );

  useLayoutEffect(() => {
    let mounted = true;

    if (loading && mounted) {
      if (timerRef.current) {
        clearTimeout(timerRef.current);
      }

      if (query !== "idle") {
        return;
      }

      setQuery("progress");

     timerRef.current = window.setTimeout(() => {
        setQuery("success");
      }, 2000);
    }

    return () => {
      mounted = false;
    };
  }, [loading, query]);

  return (
    <Fade
      in={query === "progress"}
      style={{
        transitionDelay: query === "progress" ? "800ms" : "0ms",
      }}
      unmountOnExit
    >
      <CircularProgress className="spinner__img" size={50} />
    </Fade>
  );
};

export default SpinnerImage;
