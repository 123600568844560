import React, { useState, useRef } from "react";

// Hooks
import { useTranslation } from "react-i18next";
import { debounce } from "lodash";
import { useNavigate } from "react-router-dom";
import { useDidUpdateEffect } from "../../../../hooks/useUpdateEffect";
import useBackListener from "../../../../hooks/useBackListener";

// Component
import SearchBarSection from "../Components/SearchBarSection";
import SearchHelperBlock from "../Components/SearchHelperBlock";
import NoResultBlock from "../Components/NoResultBlock";
import SearchResultCategoryItems from "../Components/SearchResultCategoryItems";
import Spinner from "../../../Reusable/Spinner";
import SearchFiltering from "./SearchFiltering";

// Services
import SearchServices from "../Services/SearchServices";
import UserService from "../../../../services/UserService";
import DynamicContentServices from "../../../../services/DynamicContentServices";

// Localization

// Lib

// utils
import { limitItems } from "../../../../utils/CommonFunctions";

// Context
import { useFetchSearchProps } from "../context/SearchWidgetContext";

const SearchResults = () => {
  const [t] = useTranslation("translations");
  const history = useNavigate();

  const {
    searchFilters,
    searchWidgetProps,
    searchResults,
    queryString,
    isFilteringActive,
    position,
    distance,
    setSearchResults,
    setSearchWidgetConfig,
    setQueryString,
    setSearchFilters,
    setSelectedAccordionItemID,
    setIsFilteringActive,
    setSelectedCategoryAccordionIDs,
  } = useFetchSearchProps();
  const [searchableContent, setSearchableContent] = useState([]);
  const [suggestedSearches, setSuggestedSearches] = useState([]);
  const [recentSearches, setRecentSearches] = useState([]);
  const [isLoadingResults, setLoadingResults] = useState(false);
  const [isSearching, setIsSearching] = useState(false);

  // Back button to always go back to homepage
  useBackListener(() => {
    setSearchResults([]);
    setQueryString("");
    setSearchFilters({});
    setSelectedAccordionItemID([]);
    setIsFilteringActive(false);
    setSelectedCategoryAccordionIDs([]);
    history("/", { replace: true });
  });

  // 1. Fetch Recent Searches

  useDidUpdateEffect(() => {
    fetchRecentSearches();

    const fetchSearchableContentList = async () => {
      // Fetch the saved props
      const fetchSavedProps: any = JSON.parse(
        localStorage.getItem("search_widget_props")
      );

      if (fetchSavedProps) {
        setSearchWidgetConfig(fetchSavedProps);
        let result;

        if (fetchSavedProps.search_resource) {
          // 1. Fetch the search content
          result = await DynamicContentServices.getDynamicContent?.(
            fetchSavedProps.search_resource,
            fetchSavedProps.search_resource_boolean
          );

          // 2. Filter the searchable content based on Location saved/change in location

          const location =
            UserService.getAttribute<"savedLocationOht">("savedLocationOht");

          const formatUserSelectedLocation = {
            position,
            distance,
          };
          let locationToUseToFilter;

          // 2.1 Prepare the location selection
          // 2.1.1 If there is a filter selection

          if (
            formatUserSelectedLocation.position &&
            formatUserSelectedLocation.position?.latitude &&
            formatUserSelectedLocation.position?.longitude &&
            location !== undefined &&
            location.type === "location" &&
            (formatUserSelectedLocation?.distance !== location.distance ||
              JSON.stringify(formatUserSelectedLocation?.position) !==
                JSON.stringify(location.position))
          ) {
            locationToUseToFilter = new Array(formatUserSelectedLocation);
          } else {
            // 2.1.2 If no filter use the user saved locations
            if (location !== undefined && location.type === "location") {
              locationToUseToFilter = new Array(location);
            }
          }

          // 2.2 Filter content based on the
          if (locationToUseToFilter && locationToUseToFilter.length) {
            result =
              await DynamicContentServices.filterDynamicContentBasedOnLocation?.(
                result,
                locationToUseToFilter
              );
          }

          setSearchableContent(result);
        }
      }
    };

    fetchSearchableContentList();
  }, []);

  // 2. Suggested Searches

  useDidUpdateEffect(() => {
    const fetchSearchResults = async () => {
      try {
        if (queryString) {
          await fetchSuggestedSearches.current(queryString);
        }
      } catch (err) {
        console.log("err", err);
      }
    };

    fetchSearchResults();
  }, [queryString]);

  // Searching after only enter key is pressed

  useDidUpdateEffect(() => {
    const keypressListener = async (event: any) => {
      if (queryString && (event.code === "Enter" || event.keyCode === 13)) {
        try {
          // Loader
          setLoadingResults(true);
          setIsSearching(true);

          event.preventDefault();

          // 2. SearcH Logic
          await handleSearch(
            queryString,
            recentSearches,
            searchableContent,
            searchWidgetProps
          );
        } catch (err) {
          console.log("Err", err);
        }
      }
    };

    document.addEventListener("keydown", keypressListener);

    return () => {
      document.removeEventListener("keydown", keypressListener);
    };
  }, [queryString, recentSearches, searchWidgetProps]);

  // 4. Search Filtering Logic
  // 4.1 Location based filtering
  // 4.2 Only run this when filtering is active i.e if user has selected some filtering options

  useDidUpdateEffect(() => {
    const searchFilteringResults = async () => {
      let filteredResults: any = [];
      let tempSearchAbleContent = [];

      if (isFilteringActive && queryString) {
        // 4.1 Search Filters.

        try {
          setIsSearching(true);

          // 4.2. Ungroup the searched results
          // Easier to search

          // 4.2.1 IF the search results are already there use this data to filter
          console.log("searchResults", searchResults);
          if (Object.keys(searchResults).length !== 0) {
            tempSearchAbleContent =
              await SearchServices.handleUnGroupingResultsByCategory(
                searchResults
              );
            console.log("Hit1", searchableContent);
          } else {
            // 4.2.1 Else Use the resource data
            console.log("Hit2", searchableContent);
            tempSearchAbleContent = searchableContent;
          }
          console.log("tempSearchAbleContent", tempSearchAbleContent);
          // 4.3. Search The Prepared content with each selected filter key and values selected
          filteredResults = await SearchServices.handleFilteringLogic(
            tempSearchAbleContent,
            searchFilters
          );

          if (filteredResults.length) {
            // 4.3.1 PreparingData for display
            // Adding component Settings

            filteredResults =
              await DynamicContentServices.handleFormatItemsBasedOnComponentToUse(
                filteredResults,
                searchWidgetProps.ItemComponentToUse,
                searchWidgetProps.ItemComponentSettings
              );

            // 4.3.2 Group back all the Filtered Results
            filteredResults =
              await SearchServices.handleGroupingResultsByCategory(
                filteredResults,
                searchWidgetProps.groupItemsBy
              );

            // 4.3.3 Sorting By Categories
            filteredResults = Object.fromEntries(
              Object.entries(filteredResults).sort()
            );
          }

          setSearchResults(filteredResults);
          setIsFilteringActive(false);
        } catch (err) {
          console.log("err", err);
        }
      }
    };

    searchFilteringResults();
  }, [searchableContent, queryString, isFilteringActive, searchFilters]);

  // Onchange event for input

  const handleOnChange = (e: any) => {
    setQueryString(e?.target?.value);
    setIsSearching(false);

    if (!e?.target?.value) {
      // Clear SearchResults after clicking cancel
      // Clear Filters as well

      setSearchResults([]);
      setSearchFilters({});
    }
  };

  const handleUpdateQuerystring = async (selectedString: string) => {
    setQueryString(selectedString);
    setLoadingResults(true);
    setIsSearching(true);

    // 2. SearcH Logic
    await handleSearch(
      selectedString,
      recentSearches,
      searchableContent,
      searchWidgetProps
    );
  };

  // Complete Search Function

  const handleSearch = async (
    queryString: string,
    recentSearches: Array<string>,
    searchableContent: Array<any>,
    searchWidgetProps: any
  ) => {
    let tmpResults: any = [];

    // 1. If searchable content is available
    // Loop through it and check with if searchable keys provided in config

    tmpResults = await SearchServices.handleSearchingLogic(
      searchWidgetProps?.searchable_keys,
      searchableContent,
      queryString
    );

    if (tmpResults.length) {
      // 2. Adding component Settings
      tmpResults =
        await DynamicContentServices.handleFormatItemsBasedOnComponentToUse(
          tmpResults,
          searchWidgetProps.ItemComponentToUse,
          searchWidgetProps.ItemComponentSettings
        );

      // 3. Grouping based on the Categories

      tmpResults = await SearchServices.handleGroupingResultsByCategory(
        tmpResults,
        searchWidgetProps.groupItemsBy
      );

      // 3.1 Sorting By Categories
      tmpResults = Object.fromEntries(Object.entries(tmpResults).sort());
    }

    // 4. If the querystring already used before move to front

    recentSearches = await SearchServices.handleRecentSearchesLogic(
      recentSearches,
      queryString,
      tmpResults
    );

    updateRecentSearches(recentSearches);
    setSearchResults(tmpResults);
    setLoadingResults(false);
  };

  // Get Suggested Searches

  const fetchSuggestedSearches = useRef(
    debounce((currentQueryString) => new Promise<void>(async (resolve, reject) => {
        try {
          const result = await SearchServices.getSuggestedSearches(
            currentQueryString
          );

          setSuggestedSearches(result);
          setLoadingResults(false);
          resolve();
        } catch (error) {
          console.log("error", error);
        }
      }), 1000)
  );

  // Get Recent Searches

  const fetchRecentSearches = () => {
    let result: any = JSON.parse(localStorage.getItem("recent_searches")) || [];

    result = limitItems(
      result,
      searchWidgetProps.numberOfItemsToShowForRecentSearches,
    );

    setRecentSearches(result);
  };

  // Update Recent Searches

  const updateRecentSearches = (recentSearches: Array<string>) => {
    let tempRecentSearches: any = recentSearches;

    tempRecentSearches = limitItems(
      tempRecentSearches,
      searchWidgetProps.numberOfItemsToShowForRecentSearches,
    );

    localStorage.setItem("recent_searches", JSON.stringify(tempRecentSearches));
    setRecentSearches(tempRecentSearches);
  };

  return (
    <div className="searchResultsPage">
      <SearchBarSection
        queryString={queryString}
        handleOnChange={handleOnChange}
        handleNavigateToFilterPage={() => {
          history(`/${SearchFiltering.name}`);
        }}
        searchWidgetProps={searchWidgetProps}
        showFilter
      />

      {/* Spinner */}

      {isLoadingResults ? <Spinner /> : null}

      {/* Empty Search Results */}

      {(queryString || isFilteringActive) &&
      isSearching &&
      Object.keys(searchResults).length === 0 &&
      !isLoadingResults ? (
        <NoResultBlock
          isFilteringActive={isFilteringActive}
          queryString={queryString}
        />
      ) : null}

      {/* Search Results */}

      {Object.keys(searchResults).length > 0 ? (
        <SearchResultCategoryItems searchResults={searchResults} />
      ) : null}

      {/* Recent Searches */}

      {!queryString &&
      Object.keys(searchResults).length === 0 &&
      recentSearches?.length ? (
        <SearchHelperBlock
          headerText={t("SearchWidget.recent_searches_title")}
          data={recentSearches}
          handleUpdateQuerystring={handleUpdateQuerystring}
        />
      ) : null}

      {/* Popular Searches */}

      {Object.keys(searchResults).length === 0 &&
      searchWidgetProps?.popular_searches?.length &&
      (isSearching || !queryString) ? (
        <SearchHelperBlock
          headerText={t("SearchWidget.popular_searches_title")}
          data={searchWidgetProps?.popular_searches}
          handleUpdateQuerystring={handleUpdateQuerystring}
        />
      ) : null}

      {/* Suggested Searches */}

      {queryString &&
      !isSearching &&
      suggestedSearches?.length &&
      Object.keys(searchResults).length === 0 ? (
        <SearchHelperBlock
          headerText={t("SearchWidget.suggested_searches_title")}
          data={suggestedSearches}
          handleUpdateQuerystring={handleUpdateQuerystring}
        />
      ) : null}
    </div>
  );
};

export default SearchResults;
