import React from 'react'
import { ErrorPage, ErrorIcon, Title, Description, Button } from './ErrorPage.wrapper'
import { useTranslation } from "react-i18next";


const ErrorNoNetworkPage = () => {
  const [t] = useTranslation("translations");

  return (
    <ErrorPage>
        <ErrorIcon icon_type='network' />
        <Title>{t('ErrorPage.error_no_network_page__title')}</Title>
        <Description>{t("ErrorPage.error_no_network_page__description")}</Description>
        <Button>{t("Try Again")}</Button>
    </ErrorPage>
  )
}

export default ErrorNoNetworkPage