import React from "react";

// Svgs 
import {ReactComponent as SearchIcon} from '../../../../assets/icon_magnifier.svg';

// Localization
import { useTranslation } from 'react-i18next';

// Types
import { searchWidgetProps }  from  "../context/SearchWidgetContext";

const SearchBarSection = ({ queryString, handleOnChange, handleOnClick, searchWidgetProps, showFilter, handleNavigateToFilterPage }: { queryString?: string, handleOnChange?: any, handleOnClick?: () => void, searchWidgetProps?: searchWidgetProps, showFilter: boolean, handleNavigateToFilterPage?: any }) => {
  const [t] = useTranslation("translations");

  return (
    <div className="search-Container">
        <div className="input-wrapper">
          <SearchIcon className="search-icon" />

          <input 
            className={`search-input ${searchWidgetProps?.classname} ${!queryString ? "inactive" : ""}`}
            name={searchWidgetProps?.search_input_name || "search"}
            type="text"
            onChange={handleOnChange} 
            placeholder={t("SearchWidget.placeholder_text")}
            maxLength={searchWidgetProps?.searchMaxLength || 100}
            value={queryString}
            aria-label="Search"
            onClick={handleOnClick}
          />

          {/* Cancel Button in Input */}

          {
            queryString
            &&
            <button 
              className="cancel-button" 
              onClick={handleOnChange}
              dangerouslySetInnerHTML={{ __html: t('SearchWidget.cancel_button_text') }}
            />
          }
        </div>

        {/* Filter Button */}

        {
          (!showFilter || searchWidgetProps?.hideFilter)
          ?
          null
          :
          <button className="filter-button" onClick={handleNavigateToFilterPage}>
            { t("SearchWidget.filter_button_text") }
          </button>
        }
      </div>
  );
};

export default SearchBarSection;
 