/* eslint-disable max-len */
/* eslint-disable jsx-a11y/anchor-is-valid */
import "./styles/select-your-health-plan-email.css"


export default () => (
  <div className="body">
    <header>
      <h1 className="header__h1">Reminder Email: Please select your Health Plan</h1>
      <div className="header-logo tinted-background">
        <img className="header-logo__img" src="assets/south-carolina/default/healthy-connections-logo.svg" alt="Health Connections logo" />
      </div>
    </header>
    
    <section>
      <div>
        <p className="p">Hi David,</p>
        <p className="p">You are currently enrolled in South Carolina&apos;s Medicaid program, Healthy Connections. Now, you must choose a health plan (also known as a Managed Care Organization).</p>
        <p className="p">Health plans provide the same benefits as regular Medicaid. They also offer extra services that may help you and your family get better care.</p>
        <p className="p">To compare the different health plans check out our Health Plan Comparison Chart. When you choose a health plan, you can choose your own doctor (or clinic). That doctor will be your primary care physician (PCP). Your PCP will be your main doctor - they will provide most of your medical and health care and help you stay healthy.</p>
        <p className="p">To learn more about Healthy Connections Medicaid program <a className="anchor" href="#">click here.</a></p>
        <p className="p">To compare the different health plans check out our <a className="anchor" href="#">Health Plan Comparison Chart.</a></p>
      </div>
      <div className="button-wrapper">
        <a className="anchor" href="ChangeLocation">Choose your Health Plan <img src="/assets/south-carolina/icon/double-arrow-right.svg" alt="" /></a>
      </div>
    </section>

    <footer className="footer tinted-background">
      <p className="p">This is an automated message. Do not respond to this email. For assistance, <a className="anchor" href="#">Contact Us.</a></p>
      <p className="p">Go <a className="anchor" href="#">here</a> to unsubscribe.</p>
    </footer>
  </div>
);
