import React from "react";

// Components
import Button from "../Reusable/Button";

// Utils
import { handleOverrideClassName, handleOverrideConfig, handleRedirect } from "../../utils/CommonFunctions";

// Services
import i18n from "../../i18n";

const A1 = ({ 
    item,
    theme,
    history,
    pageName
}) => {

    const renderButtons = () => {
        if (item.action_buttons && item.action_buttons.length) {
            return (
                item.action_buttons.map((item1, i) => (
                    <Button 
                        key={i}
                        buttonType={handleOverrideConfig(theme.buttonType, item1.buttonType)}
                        buttonClassname={handleOverrideClassName(item1.buttonClassname)}
                        buttonText={item1.buttonText}
                        handleRedirect={() => handleRedirect(item1.button_action, history, item1.buttonText, pageName)}
                        showButtonIcon={item1.showButtonIcon}
                        buttonIconImage={item1.buttonIconImage}
                        buttonIconPosition={item1.buttonIconPosition}
                        buttonIconClassname={handleOverrideClassName(item1.buttonIconClassname)}
                    />
                ))
            )

        } else {
            return (      
                <Button 
                    buttonType={handleOverrideConfig(theme.buttonType, item.buttonType)}
                    buttonClassname={handleOverrideClassName(item.buttonClassname)}
                    buttonText={item.buttonText}
                    handleRedirect={() => handleRedirect(item.button_action, history, item.buttonText, pageName)}
                    showButtonIcon={item.showButtonIcon}
                    buttonIconImage={item.buttonIconImage}
                    buttonIconPosition={item.buttonIconPosition}
                    buttonIconClassname={handleOverrideClassName(item.buttonIconClassname)}
                />
            )
        }
    }
    
    return (
        <div 
            className={`a1 ${handleOverrideClassName(item.classname)}`} 
            style={
                Object.assign({
                    boxShadow: handleOverrideConfig(theme.dropShadow, item.dropShadow) && '0px 3px 6px rgba(0, 0, 0, 0.23), 0px 3px 6px rgba(0, 0, 0, 0.16)', 
                }, {})
            }
        >   

            <div className="content-container">

                <div className="title-wrapper">
                    {
                        (handleOverrideConfig(theme.showImage, item.showImage) && item.icon_image)
                        &&
                        <img className={`img-icon ${handleOverrideClassName(item.iconClassname)}`} src={item.icon_image.path} alt={item.icon_image.alt_text} />
                    }

                    {/* Title */}

                    {
                        item.title
                        &&
                        <h3 className={`header-text ${handleOverrideClassName(item.headerTextClassname)}`}>{i18n.t(item.title)}</h3>
                    }
                </div>


                {/* Description */}

                {
                    item.description
                    &&
                    <p className={`bodyText ${handleOverrideClassName(item.bodyTextClassname)}`}>{i18n.t(item.description)}</p>
                }
            </div>


            {
                handleOverrideConfig(theme.showButton, item.showButton)
                &&
                <div className="button-wrapper" style={{ justifyContent: handleOverrideConfig(theme.buttonAlignMent, item.buttonAlignMent) === "left" ? "flex-start" : handleOverrideConfig(theme.buttonAlignMent, item.buttonAlignMent) === "right" ? "flex-end" : "center" }}>
                    {
                        renderButtons()
                    }
                </div>
            }
        </div>
    );
}

export default A1;
