import { useState } from "react";
import { StaticWidgetTypes, useApp } from "../context/AppContext";
import { useDidUpdateEffect } from "./useUpdateEffect";


const useRouteRegistrationService = () => {
  const [isRouteRegistrationServiceReady, setIsRouteRegistrationServiceReady] =
    useState<boolean>(false);

  const { setStaticWidgets, configs } = useApp();


  useDidUpdateEffect(() => {
    if (configs) {
      let data: Array<StaticWidgetTypes> = [];

      configs.forEach((config) => {
        config.components.forEach(({ Component }) => {
          data.push(Component);
        });
      });

      setStaticWidgets(data);
    }
  }, [configs, isRouteRegistrationServiceReady], false);

  return {
    isRouteRegistrationServiceReady,
    setIsRouteRegistrationServiceReady,
  };
};

export default useRouteRegistrationService;