import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import { useTranslation } from "react-i18next";
import { uuid } from "uuidv4";
import useRequestSpinner from "../../../hooks/useRequestSpinner";
import FormatAddressService from "../../../services/FormatAddressService";
import UserService from "../../../services/UserService";
import SpinnerImage from "../../StaticWidgets/LOCATION_SELECTION/Components/SpinnerImage";
import LocationManager from "../../StaticWidgets/LOCATION_SELECTION/Managers/LocationManager";
import { Location, Position } from "../../StaticWidgets/LOCATION_SELECTION/Types";
import OnboardingPage, {
  Description,
  Title,
} from "../UI/OnboardingPage.wrapper";

const OnboardingLocation = () => {
  const [address, setAddress] = useState<string>("");
  const [position, setPosition] = useState<Position | undefined>();
  const [distance, setDistance] = useState<number>(20);
  const [isValidAddress, setIsValidAddress] = useState(false);
  
  const { handleRequestWithSpinner, requestLoading } = useRequestSpinner();
  
  const [t] = useTranslation("translations");

  useEffect(() => {
    const location = UserService.getAttribute(
      "savedLocationOht"
    )

    if (location !== undefined && location.type === "location") {
      const { address, distance, position } = location;
      setAddress(address.formattedAddress);
      setPosition(position);
      setDistance(distance);
      setIsValidAddress(true);
    }
  }, []);


  const handleOnSaveClick = async (
    city: string,
    postal_code: string,
    formatted_address: string
  ) => {
    if (!position) {
      return "error";
    }

    let allLocations = UserService.getAttribute("locations");
    // ADD FIRST

    // Edit if it already exists (user clicks back)
    // Get Current Location
    const currentLocationSelected = allLocations.find(
      ({ id }) =>
        id ===
        UserService.getAttribute("savedLocationOht").id
    );

    if (currentLocationSelected === undefined) {
      const addedLocation: Location = {
        id: uuid(),
        distance,
        address: {
          city: city,
          postalcode: postal_code,
          formattedAddress: formatted_address,
        },
        position,
        type: "location",
      };

      allLocations = [addedLocation, ...allLocations];

      UserService.setAttribute("locations", allLocations);
      UserService.setAttribute("savedLocationOht", addedLocation);
    } else {

      const updatedLocation: Location = {
        id: currentLocationSelected?.id,
        distance,
        address: {
          city: city,
          postalcode: postal_code,
          formattedAddress: formatted_address,
        },
        position,
        type: "location",
      };

      const updatedLocations = UserService.getAttribute("locations").map((location) => {
        if (location.id === currentLocationSelected?.id) {
          return updatedLocation
        } else {
          return location;
        }
      });

      UserService.setAttribute("locations", updatedLocations);
      UserService.setAttribute("savedLocationOht", updatedLocation);
      

    }


    return "done";
  };


  return (
    <OnboardingPage
      withSkip
      title="Set Your Preferences"
      withBack
      isNextBtnDisabled={!isValidAddress}
      nextBtnOnClick={() =>
        handleRequestWithSpinner(FormatAddressService(address), (res) =>
          handleOnSaveClick(res?.city || "", res?.postal_code || "", res?.formatted_address || "")
        )
      }
    >
      <Helmet>
        <title>{t("LocationSelection.AddLocation.page_title")}</title>
      </Helmet>
      <div className="onboarding__location">
        <Title>{t("Onboarding.Location.page_header")}</Title>
        <Description>{t("Onboarding.Location.page_description")}</Description>
      </div>
      <div>
        <LocationManager
          address={address}
          distance={distance}
          position={position}
          isValidAddress={isValidAddress}
          setAddress={setAddress}
          setDistance={setDistance}
          setPosition={setPosition}
          setIsValidAddress={setIsValidAddress}
        />
        {<SpinnerImage loading={requestLoading} />}
        <div className="ls__bottom_button"></div>
      </div>
    </OnboardingPage>
  );
};

export default OnboardingLocation;
