import React from "react";

// Lib
import parse from "html-react-parser";

// Components
import Button from "../Reusable/Button";

// Images
import ArrowIcon from "../../assets/icon_right_arrow.svg";

// Utils
import {
  handleOverrideClassName,
  handleOverrideConfig,
  handleRedirect,
} from "../../utils/CommonFunctions";

const PI2 = ({ item, theme, history, pageName }) => {
  const arrowIconImg = (item.icon_image && item.icon_image.path) || ArrowIcon;
  const arrowIconImgAlt = item.icon_image && item.icon_image.alt_text;

  // CHange Component tag name based on the condition
  const CustomTag = item.button_action?.hasActionableButton ? "div" : "button";

  return (
    <CustomTag
      className={`pi2 ${handleOverrideClassName(item.classname)} ${
        handleOverrideConfig(theme.dropShadow, item.dropShadow) ? "show-shadow" : ""
      }`}
      {...(!item.button_action?.hasActionableButton && {
            onClick: () => handleRedirect(item.button_action, history, item.title, pageName),
          })}
    >
      <div
        className="content-section"
        style={{
          cursor: `${handleOverrideConfig(theme.showIcon, item.showIcon) ? "pointer" : "unset"}`,
          borderRadius: `${!handleOverrideConfig(theme.showImage, item.showImage) && "8px"}`,
        }}
        // onClick={() => handleRedirect(item.button_action, history, item.title, pageName)}
      >
        {/* Logo Image */}
        {handleOverrideConfig(theme.showLogoImage, item.showLogoImage) && item.logo_image && (
          <img
            className={`logo-img-icon ${handleOverrideClassName(item.logoImageClassname)}`}
            src={item.logo_image.path}
            alt={item.logo_image.alt_text}
          />
        )}

        <div>
          {/* Title text */}

          {item.title && (
            <h3 className={`header-text ${handleOverrideClassName(item.headerTextClassname)}`}>
              {parse(item.title)}
            </h3>
          )}

          {/* Description */}

          {item.description && (
            <p className={`bodyText ${handleOverrideClassName(item.bodyTextClassname)}`}>
              {parse(item.description)}
            </p>
          )}

          {/* Description 2 */}

          {item.description2 && (
            <p className={`bodyText2 ${handleOverrideClassName(item.bodyText2Classname)}`}>
              {parse(item.description2)}
            </p>
          )}
        </div>

        {/* Arrow icon */}
        {handleOverrideConfig(theme.showIcon, item.showIcon) && (
          <img
            className={`icon-img ${handleOverrideClassName(item.iconClassname)}`}
            src={arrowIconImg}
            alt={arrowIconImgAlt}
          />
        )}

        {/* Action Button */}
        {item.button_action?.hasActionableButton && (
          <div className="button-wrapper">
            <Button
              buttonType={handleOverrideConfig(
                theme.buttonType,
                item.button_action?.button_settings?.buttonType
              )}
              buttonClassname={handleOverrideClassName(
                item.button_action?.button_settings?.buttonClassname
              )}
              buttonText={item.button_action?.button_settings?.buttonText}
              handleRedirect={() =>
                handleRedirect(
                  item.button_action,
                  history,
                  item.button_action?.button_settings?.buttonText,
                  pageName
                )
              }
              showButtonIcon={true} //eslint-disable-line
            />
          </div>
        )}
      </div>

      {handleOverrideConfig(theme.showImage, item.showImage) && (
        <div
          className={`img-card ${handleOverrideClassName(item.imgClassname)}`}
          style={{ backgroundImage: item.image && item.image.path && `url(${item.image.path})` }}
        />
      )}
    </CustomTag>
  );
};

export default PI2;
