import React, { SetStateAction, useReducer } from "react";
import { useContext, useState, createContext, useMemo } from "react";

type State = {
  configs: Array<ConfigType>;
};

type Action = { type: "add"; payload: ConfigType };

function reducer(state: State, action: Action) {
  if (action.type === "add") {
    return { configs: [action.payload, ...state.configs] };
  } else {
    throw new Error();
  }
}

export type ImportedComponentType = React.FC;

export type StaticWidgetTypes = React.FC

export interface ConfigType {
  name: string;
  components: Array<{ Component: ImportedComponentType }>;
}


interface AppCtxDefaultValueProps {
  isAppReady: boolean;
  setIsAppReady: (isAppReady: boolean) => void;
  isAnalyticsInitialized: boolean;
  setIsAnalyticsInitialized: (isAnalyticsInitialized: boolean) => void;

  staticWidgets: Array<StaticWidgetTypes>;
  setStaticWidgets: (
    staticWidgets: SetStateAction<Array<StaticWidgetTypes>>
  ) => void;
  configs: Array<ConfigType>;
  addConfig: (payload: ConfigType) => void;
  ohtFilter: string | undefined;
  setOhtFilter: (ohtFilter: string) => void;
}

const appCtxDefaultValue = {
  isAppReady: false,
  setIsAppReady: (isAppReady: boolean) => {
    // This is intentional, using fn body to build type
  },
  isAnalyticsInitialized: false,
  setIsAnalyticsInitialized: (isAnalyticsInitialized: boolean) => {
    // This is intentional, using fn body to build type
  },
  staticWidgets: [],
  setStaticWidgets: (
    staticWidgets: SetStateAction<Array<StaticWidgetTypes>>
  ) => {
        // This is intentional, using fn body to build type
  },
  configs: [],
  addConfig: (payload: ConfigType) => {
        // This is intentional, using fn body to build type
  },
  ohtFilter: "",
  setOhtFilter:(ohtFilter: string | undefined) => {

  }
};

const AppContext = createContext<AppCtxDefaultValueProps>(appCtxDefaultValue);

function useApp() {
  return useContext(AppContext);
}

interface AppProviderProps {
  children: React.ReactNode;
}

function AppProvider(props: AppProviderProps) {
  const [isAppReady, setIsAppReady] = useState(false);
  const [isAnalyticsInitialized, setIsAnalyticsInitialized] = useState(false);
  const [staticWidgets, setStaticWidgets] = useState<
    Array<StaticWidgetTypes>
  >([]);

  const [ohtFilter, setOhtFilter] = useState(appCtxDefaultValue.ohtFilter);

  // Memoized state management, used to reduce unnecessary state updates

  const [{configs}, dispatch] = useReducer(reducer, { configs: [] });

  const addConfig = (payload: ConfigType) => {
    dispatch({ type: "add", payload });
  };


  const providerValue = useMemo(
    () => ({
      isAppReady,
      setIsAppReady,
      isAnalyticsInitialized,
      setIsAnalyticsInitialized,
      staticWidgets,
      setStaticWidgets,
      configs,
      addConfig,
      ohtFilter,
      setOhtFilter
    }),
    [
      isAppReady,
      setIsAppReady,
      isAnalyticsInitialized,
      setIsAnalyticsInitialized,
      staticWidgets,
      setStaticWidgets,
      configs,
      ohtFilter,
      setOhtFilter
    ]
  );

  return <AppContext.Provider value={providerValue} {...props} />;
}
export { AppProvider, useApp };
