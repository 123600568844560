import { useContext, useState, createContext, useMemo } from "react";
import { rowItem } from "../../../../ComponentInterface";

// Types
import { Position } from "../../LOCATION_SELECTION/Types";

export interface SearchWidgetDefaults {
    searchWidgetProps: searchWidgetProps;
    searchFilters: any;
    selectedAccordionItemIDs: Array<string>;
    searchResults: Array<any>;
    queryString: string;
    isFilteringActive: boolean;
    selectedCategoryAccordionIDs: Array<string>;
    address: string,
    position: Position,
    distance: number,
    isValidAddress: boolean,
    userSavedLocation: {
      address: string,
      position: Position,
      distance: number,
      id: string
    },
    setSearchWidgetConfig: (props: searchWidgetProps) => void;
    setSearchFilters: (props: any) => void;
    setSelectedAccordionItemID: (props: Array<string>) => void;
    setSearchResults: (props: Array<any>) => void;
    setQueryString: (props: string) => void;
    setIsFilteringActive: (props: boolean) => void;
    setSelectedCategoryAccordionIDs: (props: Array<string>) => void;
    setAddress: React.Dispatch<React.SetStateAction<string>>,
    setPosition: React.Dispatch<React.SetStateAction<Position | undefined>>,
    setDistance: React.Dispatch<React.SetStateAction<number>>,
    setIsValidAddress: React.Dispatch<React.SetStateAction<boolean>>
    setUserSavedLocation: (props: any) => void;
}

export interface searchWidgetProps {
    itemType?: string;
    classname?: string;
    hideFilter?: boolean;
    searchMaxLength?: number;
    popular_searches?: Array<string>;
    search_resource?: string;
    numberOfItemsToShowForRecentSearches?: number;
    search_input_name?: string;
    searchable_keys?: Array<string>;
    ItemComponentToUse?: string;
    ItemComponentSettings?: rowItem;
    groupItemsBy?: string;
    search_filters?: any;
}

const appCtxDefaultValue: SearchWidgetDefaults = {
    searchWidgetProps: {
        itemType: undefined,
        classname: undefined,
        hideFilter: undefined,
        searchMaxLength: undefined,
        popular_searches: undefined,
        search_resource: undefined,
        numberOfItemsToShowForRecentSearches: undefined,
        search_input_name: undefined,
        searchable_keys: undefined,
        ItemComponentToUse: undefined,
        ItemComponentSettings: undefined,
        search_filters: undefined
    },
    searchFilters: {},  
    searchResults: [],
    selectedAccordionItemIDs: [],
    queryString: "",
    isFilteringActive: false,
    selectedCategoryAccordionIDs: [],
    address: "",
    position: {
      longitude: null,
      latitude: null
    },
    distance: null,
    isValidAddress: false,
    userSavedLocation: {
      address: "",
      position: {
        longitude: null,
        latitude: null,
      },
      distance: null,
      id: ""
    },
    setSearchWidgetConfig: (props: searchWidgetProps) => {},
    setSearchFilters: (props: any) => {},
    setSelectedAccordionItemID: (props: Array<string>) => {},
    setSearchResults: (props: Array<any>) => {},
    setQueryString: (props: string) => {},
    setIsFilteringActive: (props: boolean) => {},
    setSelectedCategoryAccordionIDs: (props: Array<string>) => {},
    setAddress: (props: any) => {},
    setPosition: (props: any) => {},
    setDistance: (props: any) => {},
    setIsValidAddress: (props: any) => {},
    setUserSavedLocation: (props: any) => {}
};

const SearchWidgetContext = createContext<SearchWidgetDefaults>(appCtxDefaultValue);

function useFetchSearchProps() {
  return useContext(SearchWidgetContext);
}

interface SearchWidgetProviderProps {
  children: React.ReactNode;
}

function SearchWidgetProvider(props: SearchWidgetProviderProps) {
  const [searchWidgetProps, setSearchWidgetConfig] = useState(appCtxDefaultValue.searchWidgetProps);
  const [searchFilters, setSearchFilters] = useState(appCtxDefaultValue.searchFilters);
  const [selectedAccordionItemIDs, setSelectedAccordionItemID] = useState(appCtxDefaultValue.selectedAccordionItemIDs);
  const [searchResults, setSearchResults] = useState(appCtxDefaultValue.searchResults);
  const [queryString, setQueryString] = useState(appCtxDefaultValue.queryString);
  const [isFilteringActive, setIsFilteringActive] = useState(appCtxDefaultValue.isFilteringActive);
  const [selectedCategoryAccordionIDs, setSelectedCategoryAccordionIDs] = useState(appCtxDefaultValue.selectedCategoryAccordionIDs);
  const [address, setAddress] = useState(appCtxDefaultValue.address);
  const [position, setPosition] = useState(appCtxDefaultValue.position);
  const [distance, setDistance] = useState(appCtxDefaultValue.distance);
  const [isValidAddress, setIsValidAddress] = useState(appCtxDefaultValue.isValidAddress);
  const [userSavedLocation, setUserSavedLocation] = useState(appCtxDefaultValue.userSavedLocation);
 
  // Memoized state management, used to reduce unnecessary state updates
  const providerValue = useMemo(
    () => ({
        searchWidgetProps,
        setSearchWidgetConfig,
        searchFilters,
        setSearchFilters,
        selectedAccordionItemIDs,
        setSelectedAccordionItemID,
        searchResults,
        setSearchResults,
        queryString,
        setQueryString,
        isFilteringActive,
        setIsFilteringActive,
        selectedCategoryAccordionIDs,
        setSelectedCategoryAccordionIDs,
        address, 
        setAddress,
        position, 
        setPosition,
        distance, 
        setDistance,
        isValidAddress, 
        setIsValidAddress,
        userSavedLocation, 
        setUserSavedLocation
    }),
    [
        searchWidgetProps,
        setSearchWidgetConfig,
        searchFilters,
        setSearchFilters,
        selectedAccordionItemIDs,
        setSelectedAccordionItemID,
        searchResults,
        setSearchResults,
        queryString,
        setQueryString,
        isFilteringActive,
        setIsFilteringActive,
        selectedCategoryAccordionIDs,
        setSelectedCategoryAccordionIDs,
        address, 
        setAddress,
        position, 
        setPosition,
        distance, 
        setDistance,
        isValidAddress, 
        setIsValidAddress,
        userSavedLocation, 
        setUserSavedLocation
    ]
  );

  return <SearchWidgetContext.Provider value={providerValue} {...props} />;
}
export { SearchWidgetProvider, useFetchSearchProps };
