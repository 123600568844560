import {
  motion,
  PanInfo,
  useAnimation,
} from "framer-motion/dist/framer-motion";
import { useState, useEffect, useRef } from "react";
import isMobile from "../../utils/isMobile";

interface ResultItemProps {
  index: number;
  onDelete: (index: number) => void;
  onClick?: React.MouseEventHandler<HTMLDivElement> | undefined;
  children?: React.ReactNode;
}

const SwipeDelete = ({
  index,
  onDelete,
  onClick,
  children
}: ResultItemProps) => {
  const controls = useAnimation();
  const [isDragging, setIsDragging] = useState(false);
  const [isTrashcanExposed, setIsTrashcanExposed] = useState(false);
  const ref = useRef<HTMLDivElement | undefined>();

  const SWIPE_LENGTH = -80;

  async function handleDragEnd(
    _: MouseEvent | TouchEvent | PointerEvent,
    info: PanInfo
  ) {
    if (Math.abs(info.delta.y) > 100) {
      // Prevent fn to trigger if a user swipes vertically on a scroll
      return;
    }

    const offset = info.offset.x;

    const acceptable_swipe = offset < SWIPE_LENGTH;

    if (acceptable_swipe) {
      await controls
        .start({ x: "-100%", transition: { duration: 0.2 } })
        .then((value) => {
          setIsDragging(false);
        });
      onDelete(index);
    } else {
      controls
        .start({ x: 0, opacity: 1, transition: { duration: 0.5 } })
        .then((value) => {
          setIsDragging(false);
        });
    }
  }

  useEffect(() => {
    if (isTrashcanExposed) {
      isMobile.android() && window.navigator.vibrate(10);
      // document might have to be the parentNode

      // need to go through dom to search for an element that this Component is inside.
      // get parentNode of this Component
      // with parentNode

      ref.current?.parentNode
        .querySelectorAll("#trashcan")
        [index].classList.add("icon-trash--active");
    } else {
      ref.current?.parentNode
        .querySelectorAll("#trashcan")
        [index].classList.remove("icon-trash--active");
    }
  }, [isTrashcanExposed, index]);

  return (
    <motion.div
    ref={ref}
      style={{
        willChange: "transform",
        cursor: "grab",
      }}
      whileTap={{ cursor: "grabbing" }}
      layout
      transition={{ type: "spring", stiffness: 600, damping: 30 }}
    >
      <motion.div className="ls__locationcard__bg">
        <motion.span
          id="trashcan"
          className={`icon-trash icon-trash--right`}
        ></motion.span>
        <motion.div
          dragDirectionLock
          drag="x"
          dragConstraints={{
            top: 0,
            right: 0,
            bottom: 0,
            left: 0,
          }}
          dragElastic={{ left: 0.5, right: 0.05 }}
          onDragEnd={(_, info) => {
            handleDragEnd(_, info);
          }}
          onDrag={(_, info) => {
            if (Math.abs(info.offset.x) > Math.abs(SWIPE_LENGTH)) {
              setIsTrashcanExposed(true);
            } else if (Math.abs(info.offset.x) < Math.abs(SWIPE_LENGTH)) {
              setIsTrashcanExposed(false);
            }
            if (Math.abs(info.offset.x) > 12) {
              setIsDragging(true);
            }
          }}
          animate={controls}
          className="ls__locationCard"
          onClick={(e) => {
            if (isDragging) return undefined;
            onClick && onClick(e);
          }}
        >
          {children}
        </motion.div>
      </motion.div>
    </motion.div>
  );
};

export default SwipeDelete;
