import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import axios from "axios";
import StepSlider from "../../../Reusable/StepSlider";
import DistanceBox from "../Components/DistanceBox";
import ResultItem from "../Components/ResultItem";
import TextField from "../Components/TextField";

import { Position } from "../Types";
import {
  insertSpaceIntoPostalCode,
  isPostalCode,
  isPostalCodeWithoutSpace,
} from "../../../../utils/isPostalCode";

interface LocationProps {
  address: string;
  setAddress: React.Dispatch<React.SetStateAction<string>>;
  position: Position | undefined;
  setPosition: React.Dispatch<React.SetStateAction<Position | undefined>>;
  distance: number;
  setDistance: React.Dispatch<React.SetStateAction<number>>;
  isValidAddress: boolean;
  setIsValidAddress: React.Dispatch<React.SetStateAction<boolean>>;
  handleOnClick?: () => void;
  isLocationFiltering?: boolean;
  onFocusInput?: React.Dispatch<React.SetStateAction<boolean>>;
  onBlurInput?: React.Dispatch<React.SetStateAction<boolean>>;
}

const LocationManager = ({
  address,
  distance,
  isValidAddress,
  setAddress,
  setDistance,
  setPosition,
  setIsValidAddress,
  isLocationFiltering,
  onFocusInput,
  onBlurInput,
}: LocationProps) => {
  const [isStepSliderActive, setIsStepSliderActive] = useState(false);
  const [searchResultsActive, setSearchResultsActive] = useState(false);
  const [placePredictions, setPlacePredictions] = useState([]);
  const [errorText, setErrorText] = useState("");

  const [t] = useTranslation("translations");

  const GeoLocationSuccess = (pos: GeolocationPosition) => {
    const { latitude, longitude } = pos.coords;

    axios
      .get(`${window.config.mtn_services}/closest.php?lng=${longitude}&lat=${latitude}`)
      .then((res) => {
        if (res.data.city === undefined)
          setErrorText(t("LocationSelection.LocationManager.error_unknown"));
        else {
          setAddress(`${res.data.city}, ${res.data.code}`);
          setPosition({ latitude: res.data.lat, longitude: res.data.lng });
          setIsValidAddress(true);
        }
      })
      .catch(() => {
        setErrorText(t("LocationSelection.LocationManager.error_unknown"));
      });

    return { latitude, longitude };
  };

  const getPlacePredictions = (input: string, retainPreviousPredictions?: boolean) =>
    axios
      .get(`${window.config.mtn_services}/lookup.php?q=${input}`)
      .then((res) =>
        setPlacePredictions([...res.data, ...(retainPreviousPredictions ? placePredictions : [])])
      );

  // * TEST
  function showError(error: GeolocationPositionError) {
    let x = "";

    switch (error.code) {
      case error.PERMISSION_DENIED:
        x = t("LocationSelection.LocationManager.error_denied");
        break;
      case error.POSITION_UNAVAILABLE:
        x = t("LocationSelection.LocationManager.error_unavailable");
        break;
      case error.TIMEOUT:
        x = t("LocationSelection.LocationManager.error_timeout");
        break;
      default:
        break;
    }

    setErrorText(x);
  }

  function getCurrentLocation() {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(GeoLocationSuccess, showError, {
        maximumAge: 60000,
        timeout: 5000,
        enableHighAccuracy: true,
      });

      if (window?.webkit?.messageHandlers?.ToApp?.postMessage) {
        window.webkit.messageHandlers.ToApp.postMessage("GetCurrentPosition");
      }
    } else {
      setErrorText(t("LocationSelection.LocationManager.error_unsupported"));
    }
  }

  useEffect(() => {
    setIsStepSliderActive(isValidAddress);
  }, [isValidAddress]);

  useEffect(() => {
    setSearchResultsActive(address.length > 0 && !isStepSliderActive);
  }, [isStepSliderActive, address.length]);

  const handlePostalOnChange = ({ target: { value } }: React.ChangeEvent<HTMLInputElement>) => {
    let modifiedValue = value;

    // Postal code Validation and trim '-', or insert space into postal code.
    if (isPostalCode(value)) {
      modifiedValue = value.replace(/-/g, " ");
    }

    if (isPostalCodeWithoutSpace(value)) {
      modifiedValue = insertSpaceIntoPostalCode(value);
    }

    setAddress(modifiedValue);
    getPlacePredictions(modifiedValue);
  };

  return (
    <div className="ls__page__inner">
      <div className="ls__input">
        <TextField
          value={address}
          setValue={setAddress}
          isLocationFiltering={isLocationFiltering}
          onFocusInput={onFocusInput}
          onBlurInput={onBlurInput}
          onChange={handlePostalOnChange}
        />

        {!isLocationFiltering && (
          <DistanceBox value={distance} isStepSliderActive={isStepSliderActive} />
        )}
      </div>

      {!isLocationFiltering && (
        <div className="ls__currentlocation">
          <p className="ls__currentlocation_error">{errorText}</p>
          <span className="icon-location" />
          <button className="ls__currentlocation_button" onClick={getCurrentLocation}>
            {t("LocationSelection.AddLocation.current_location")}
          </button>
        </div>
      )}

      {searchResultsActive && (
        <div className="ls__results">
          <p className="ls__pageheader">{t("LocationSelection.LocationManager.results")}</p>

          {placePredictions.length > 0 &&
            placePredictions.map((item, i) => (
              <ResultItem
                key={i}
                postalCode={item.code}
                city={`${item.city}, ON`}
                onClick={() => {
                  setAddress(`${item.city}, ${item.code}`);
                  setPosition({ latitude: item.lat, longitude: item.lng });
                  setIsValidAddress(true);
                }}
              />
            ))}

          {!placePredictions.length && <span>No results found.</span>}
        </div>
      )}

      {isStepSliderActive && (
        <div className={`ls__innerbox ${isLocationFiltering && "isFilterpage"}`}>
          <p className="ls__pageheader">{t("LocationSelection.LocationManager.distance")}</p>
          <StepSlider value={distance} setValue={setDistance} />
        </div>
      )}
    </div>
  );
};

export default LocationManager;
