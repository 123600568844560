// Components
import RowItems from "./RowItem";

// Utils
import { handleOverrideClassName } from "../../utils/CommonFunctions";
import addTile from "./rowHelper";

// Interfaces
import { theme as themeType, contentRow } from "../../ComponentInterface";

interface Props {
  theme: themeType;
  pageContent: contentRow;
  pageName?: string;
}

const RegularRow = ({ pageContent, theme, pageName }: Props) => {
  const allItems = pageContent?.items ?? [];

  let newArray = allItems;

  if (pageContent.additional_tile) {
    newArray = addTile(pageContent, allItems);
  }

  return (
    <>
      {(pageContent?.items) && (
        <div
          className={`row-container ${handleOverrideClassName(pageContent.rowContainerClassname)}`}
        >
          {newArray.map((item, i) => (
            <RowItems key={i} rowItem={item} theme={theme} pageName={pageName} />
          ))}
        </div>
      )}
    </>
  );
};

export default RegularRow;
