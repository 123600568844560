const FormatAddressService = async (addressToConvert: string) => {
  const args = addressToConvert.split(", ");

  return {
    city: args[0],
    postal_code: args[1],
    formatted_address: addressToConvert,
  };
};

export default FormatAddressService;
