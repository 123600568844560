export const isPostalCode = (postalCode: string) => {
  const regex =
    /^[ABCEGHJ-NPRSTVXY]\d[ABCEGHJ-NPRSTV-Z][ -]?\d[ABCEGHJ-NPRSTV-Z]\d$/gi;

  return postalCode.match(regex);
};

export const isPostalCodeWithoutSpace = (postalCode: string) => {
  const regex =
    /^[ABCEGHJ-NPRSTVXY]\d[ABCEGHJ-NPRSTV-Z]?\d[ABCEGHJ-NPRSTV-Z]\d$/gi;

  return postalCode.match(regex);
};

export const insertSpaceIntoPostalCode = (postalCode: string) => {
  return postalCode.substring(0, 3) + " " + postalCode.substring(3, 6);
};
