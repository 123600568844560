import React from "react";

// Component 
import RowItems from "../../../Reusable/RowItem";

// Interfaces
import { rowItem } from "../../../../ComponentInterface";

const SearchResultCategoryItems = ({ searchResults }: { searchResults: any }) => {
  
  const handleRenderCategory = () => {
    return (
      Object.keys(searchResults).map((key, i) => (
        <section className="category-section" key={i}>
          <h3>{key}</h3>
          
          {
            handleRenderCategoryItems(searchResults[key])
          }
        </section>
      ))
    )
  }

  const handleRenderCategoryItems = (items: any) => {
    return (
      items.map((item: rowItem, i: string) => (
          <RowItems 
              key={i}
              rowItem={item}
              theme={window.config.theme}
          />
      ))

    )
  }

  return (
    <>
      {
        handleRenderCategory()
      }
    </>
  );
};

export default SearchResultCategoryItems;
 