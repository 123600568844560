import React, { useState } from "react";

// Hooks
import { useDidUpdateEffect } from "../../../../hooks/useUpdateEffect";

// Component
import SearchResults from "./SearchResults";
import CheckboxButton from "../Components/CheckboxButton";
import Icon from "../../../Reusable/Icon";

// Services
import UserService from "../../../../services/UserService";
import DynamicContentServices from "../../../../services/DynamicContentServices";
import LocationManager from "../../LOCATION_SELECTION/Managers/LocationManager";
// import LocationManager from "../../LOCATION_SELECTION/Managers/Location";

// Lib
import { useNavigate } from "react-router-dom";

// Localization
import { useTranslation } from "react-i18next";

// Context
import { useFetchSearchProps } from "../context/SearchWidgetContext";

const SearchFiltering = () => {
  const [t] = useTranslation("translations");
  const history = useNavigate();
  const {
    searchFilters,
    searchWidgetProps,
    selectedAccordionItemIDs,
    selectedCategoryAccordionIDs,
    setSearchWidgetConfig,
    setSearchFilters,
    setSelectedAccordionItemID,
    setIsFilteringActive,
    setSelectedCategoryAccordionIDs,
    address,
    setAddress,
    position,
    setPosition,
    distance,
    setDistance,
    isValidAddress,
    setIsValidAddress,
    userSavedLocation,
    setUserSavedLocation,
  } = useFetchSearchProps();
  const [toggleFilterCategories, setToggleFilterCategories] = useState(false);
  const [localPosition, setLocalPosition] = useState({
    longitude: null,
    latitude: null,
  });
  const [localDistance, setLocalDistance] = useState(null);
  const [localAddress, setLocalAddress] = useState("");

  // 1. Fetchsaved Oht
  useDidUpdateEffect(() => {
    // 1.1 Filter history once we searched once
    if (address && position) {
      setLocalPosition(position);
      setLocalDistance(distance);
      setLocalAddress(address);
      setIsValidAddress(true);
      setUserSavedLocation({ position, distance, address });
    } else {
      // 1.2 Saved location for the first time

      const location =
        UserService.getAttribute<"savedLocationOht">("savedLocationOht");

      if (location !== undefined && location.type === "location") {
        const { address, distance, position } = location;

        setLocalAddress(address.formattedAddress);
        setLocalPosition(position);
        setLocalDistance(distance);
        setIsValidAddress(true);
        setUserSavedLocation(location);
      }
    }
  }, []);

  // 2. Fetch the saved props
  useDidUpdateEffect(() => {
    const fetchSearchableContentList = async () => {
      const fetchSavedProps: any = JSON.parse(
        localStorage.getItem("search_widget_props")
      );

      // Prepare the categories filter data

      const getCategoriesData = await DynamicContentServices.getDynamicContent(
        fetchSavedProps.search_filters["categories"].data,
        fetchSavedProps.search_filters_boolean
      );

      fetchSavedProps.search_filters["categories"].data = getCategoriesData;

      if (fetchSavedProps) {
        setSearchWidgetConfig(fetchSavedProps);
      }
    };

    fetchSearchableContentList();
  }, []);

  // Accordion Toggle Function

  const handleToggleAccordionItem = (key: string) => {
    let result = selectedAccordionItemIDs;

    // Check if the key is already there
    // if there meaning close the accordion i.e filter it out
    if (result.includes(key)) {
      result = result.filter((item) => item !== key);
    } else {
      // Else open up the accordion by adding ids
      result = [key, ...result];
    }

    setSelectedAccordionItemID(result);
  };

  // Accordion

  const handleRenderFilteringAccordion = () => {
    if (
      searchWidgetProps.search_filters &&
      Object.keys(searchWidgetProps.search_filters).length !== 0
    ) {
      return Object.keys(searchWidgetProps.search_filters).map((key, i) => (
        <section key={i}>
          <button onClick={() => handleToggleAccordionItem(key)}>
            <h3>{t(searchWidgetProps.search_filters[key].title)}</h3>

            {selectedAccordionItemIDs.includes(key) ? (
              <Icon iconName="down_arrow" className="top_arrow" />
            ) : (
              <Icon iconName="down_arrow" className="down_arrow" />
            )}
          </button>

          {/* Accordion Content */}

          {handleRenderAccordionItemContent(key)}
        </section>
      ));
    }
  };

  const handleRenderAccordionItemContent = (key: string) => {
    //  Render Content for the rest of filters IF data has the length
    if (
      selectedAccordionItemIDs.includes(key) &&
      searchWidgetProps.search_filters[key].data?.length &&
      typeof searchWidgetProps.search_filters[key].data !== "string"
    ) {
      // Prepare Data for categories and subcategories
      return (
        <>
          {key === "categories" ? (
            <div className="content-container content-container--categories">
              {searchWidgetProps.search_filters[key].data.map(
                (item: any, i: number) => (
                  <React.Fragment key={i}>
                    <CheckboxButton
                      item={item.title}
                      icon={item.icon}
                      showIcon={true}
                      iconFill={item.icon_fill}
                      handleOnchange={(e) => handleOnchange(e, key)}
                      handleOnClick={() => handleCategoryOnClick(item.title)}
                      defaultChecked={
                        Object.keys(searchFilters).length !== 0 &&
                        searchFilters[key] &&
                        searchFilters[key].includes(item.title)
                      }
                    />

                    {handleRenderSubCategories(item, key)}
                  </React.Fragment>
                )
              )}
            </div>
          ) : (
            <div className="content-container">
              {searchWidgetProps.search_filters[key].data.map(
                (item: any, i: number) => (
                  <CheckboxButton
                    key={i}
                    item={item}
                    handleOnchange={(e) => handleOnchange(e, key)}
                    defaultChecked={
                      Object.keys(searchFilters).length !== 0 &&
                      searchFilters[key] &&
                      searchFilters[key].includes(item)
                    }
                  />
                )
              )}
            </div>
          )}
        </>
      );
    }
  };

  // Subcategories

  const handleRenderSubCategories = (category: any, key: string) => {
    if (
      selectedCategoryAccordionIDs.includes(category.title) &&
      category.subcategories?.length
    ) {
      return (
        <div className="sub-categories">
          {category.subcategories.map((item2: any, i: number) => (
            <CheckboxButton
              key={i}
              item={item2.title}
              showIcon={false}
              icon={category.icon}
              iconFill="#1A1A1A"
              handleOnchange={(e) => handleOnchange(e, `subcategories`)}
              defaultChecked={
                Object.keys(searchFilters).length !== 0 &&
                searchFilters[key] &&
                searchFilters[key].includes(item2.title)
              }
            />
          ))}
        </div>
      );
    }
  };

  const handleCategoryOnClick = (title: string) => {
    let result = selectedCategoryAccordionIDs;

    // Check if the key is already there
    // if there meaning close the accordion i.e filter it out
    if (result.includes(title)) {
      result = result.filter((item) => item !== title);
    } else {
      // Else open up the accordion by adding ids
      result = [title, ...result];
    }

    setSelectedCategoryAccordionIDs(result);
  };

  // On Change Event for Checkbox buttton

  const handleOnchange = (e: any, key: string) => {
    let result = JSON.parse(JSON.stringify(searchFilters));

    // If the Option is selected

    if (e.target.checked) {
      // Add to the filter value array

      if (key in result) {
        result[key] = [...result[key], e.target.value];
      } else {
        result[key] = [e.target.value];
      }
    } else {
      // If the Option is deselected

      result[key] = result[key].filter(
        (item: string) => item.toLowerCase() !== e.target.value.toLowerCase()
      );
    }

    setSearchFilters(result);
  };

  const handleSearchResultsActionButton = () => {
    setIsFilteringActive(true);
    setPosition(localPosition);
    setDistance(localDistance);
    setAddress(localAddress);

    return history(`/${SearchResults.name}`);
  };

  // Check if user has selected atleast one option otherwise disable

  const handleSearchResultsActionButtonActive = () => {
    let result = true;
    const keys = Object.keys(searchFilters);

    if (
      (keys.length !== 0 && keys.some((item) => searchFilters[item].length)) ||
      JSON.stringify(userSavedLocation?.position) !==
        JSON.stringify(localPosition) ||
      userSavedLocation?.distance !== localDistance
    ) {
      result = false;
    }

    return result;
  };

  return (
    <div className="searchFilterPage">
      {/* Location Selector */}
      <LocationManager
        address={localAddress}
        distance={localDistance}
        position={localPosition}
        isValidAddress={isValidAddress}
        setAddress={setLocalAddress}
        setDistance={setLocalDistance}
        setPosition={setLocalPosition}
        setIsValidAddress={setIsValidAddress}
        isLocationFiltering={true}
        onFocusInput={() => setToggleFilterCategories(true)}
        onBlurInput={() => setToggleFilterCategories(false)}
      />

      {toggleFilterCategories ? null : (
        <>
          {/* Filtering Accordion */}

          {handleRenderFilteringAccordion()}
        </>
      )}

      {/* Footer Button */}

      <footer>
        <button
          className="search-results-btn"
          onClick={() => handleSearchResultsActionButton()}
          disabled={handleSearchResultsActionButtonActive()}
        >
          {t("SearchWidget.search_filter_action_button")}
        </button>
      </footer>
    </div>
  );
};

export default SearchFiltering;
