import Icon, { IconEnum } from '../../../Reusable/Icon';

const CheckboxButton = ({ item, handleOnchange, defaultChecked, icon, iconFill, handleOnClick, showIcon }: { item: string, handleOnchange: (e: any) => void, defaultChecked?: boolean, icon?: string, iconFill?: string , handleOnClick?: (e: any) => void, showIcon?: boolean }) => {
  return (
    <label className="checkbox-button">
      <input type="checkbox" value={item} onChange={handleOnchange} defaultChecked={defaultChecked} />
      <p className={icon ? "left-align checkbox-text" : "checkbox-text"} onClick={handleOnClick}>
        {
          showIcon
          ?
          <Icon iconName={icon as IconEnum} stroke={iconFill} />
          :
          null
        }
        <span>{item}</span>
      </p>
    </label>
  );
};

export default CheckboxButton;
 