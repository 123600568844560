const isMobile = {
  android() {
    return /Android/i.test(navigator.userAgent);
  },
  iOS() {
    return /iPhone|iPad|iPod/i.test(navigator.userAgent);
  },
};

export default isMobile;

