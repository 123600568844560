import { useEffect, useReducer, useState } from 'react'
import { useTranslation } from 'react-i18next';
import UserService from '../../../services/UserService';
import Interests from '../Components/Interests/Interests';
import { reducer } from '../Components/Interests/reducer';
import OnboardingPage, { Description, Title } from '../UI/OnboardingPage.wrapper'

const OnboardingInterests = () => {
  const [t] = useTranslation("translations");

  const [{interests}, dispatch] = useReducer(reducer, { interests: UserService.getAttribute("userPreferences").interests || []})


  const [isDisabled, setIsDisabled] = useState<boolean>(true);

  useEffect(() => {
    if(interests.length > 0) {
      setIsDisabled(false);
    } else {
      setIsDisabled(true);
    }

  }, [interests])

  const handleOnSaveClick = () => {
    UserService.setAttribute("userPreferences", {...UserService.getAttribute("userPreferences"), interests });
  };

  return (
    <OnboardingPage
      withSkip
      title="Set Your Preferences"
      withBack
      isNextBtnDisabled={isDisabled}
      nextBtnOnClick={() =>
        handleOnSaveClick()
      }
    >
      <Title>{t("Onboarding.Interests.page_header")}</Title>
      <Description>{t("Onboarding.Interests.page_description")}</Description>
      <Interests interests={interests} dispatch={dispatch} />
    </OnboardingPage>
  );
}
export default OnboardingInterests