import i18n from "i18next";
import LanguageDetector from "i18next-browser-languagedetector";
import { initReactI18next } from "react-i18next";
import XHR from "i18next-xhr-backend";

const detectionOptions = {
  order: [
    "querystring",
    "navigator",
    "htmlTag",
    "cookie",
    "localStorage",
    "sessionStorage",
    "path",
    "subdomain",
  ],
};

i18n
  .use(XHR)
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    backend: {
      loadPath: "./i18n/{{lng}}.json",
    },
    load: "languageOnly",
    detection: detectionOptions,
    fallbackLng: "en",
    debug: false,

    // have a common namespace used around the full app
    ns: ["translations"],
    defaultNS: "translations",
    nsSeparator: "",

    interpolation: {
      escapeValue: false,
    },
    react: {
      useSuspense: false,
      transEmptyNodeValue: "i18n empty", // what to return for empty Trans
      transSupportBasicHtmlNodes: true, // allow <br/> and simple html elements in translations
      transKeepBasicHtmlNodesFor: ["br", "strong", "i"], // don't convert to <1></1> if simple react elements
    },
  });

export default i18n;
