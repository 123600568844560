import React, { Suspense, useEffect } from "react";
import App from "./App";
import Spinner from "./components/Reusable/Spinner";
import { ErrorBoundary } from "react-error-boundary";
import ErrorHandler from "./components/ErrorHandler/ErrorHandler";
import { AppProvider } from "./context/AppContext";
import { SearchWidgetProvider } from "./components/StaticWidgets/SEARCH_BAR/context/SearchWidgetContext";

interface SetupProps {
  children?: React.ReactNode;
}

const appHeight = () => {
  const doc = document.documentElement
  doc.style.setProperty('--app-height', `${window.innerHeight}px`)
}
window.addEventListener('resize', appHeight)

// disables all console.logs in production
process.env.NODE_ENV === "production" && (console.log = () => {});

const Setup = ({ children }: SetupProps) => {

  useEffect(() => {
    appHeight();
  }, [])

  
  return (
    <Suspense fallback={<Spinner />}>
      <ErrorBoundary
        FallbackComponent={({ error }) => <ErrorHandler error={error} />}
      >
        <AppProvider>
          <SearchWidgetProvider>  
            <App />
          </SearchWidgetProvider>       
        </AppProvider>
        {children}
      </ErrorBoundary>
    </Suspense>
  );
};

export default Setup;
