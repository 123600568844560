import { useEffect, useState } from "react";
import { useErrorHandler } from "react-error-boundary";
import { useApp } from "../context/AppContext";
import i18n from "../i18n";

type languageStateType = string | undefined;

/**
 * A hook used in order to track and manage a failure of an i18n localization file.
 * @returns void
 */
const useManageLocalizationFailure = () => {
  const [hasLocalizationFailed, setHasLocalizationFailed] = useState(false);
  const [localizationFile, setLocalizationFile] = useState<languageStateType>();
  const { isAnalyticsInitialized } = useApp();

  const handleError = useErrorHandler();

  i18n.on("failedLoading", function (res) {
    setLocalizationFile(`${res}.json`)
    setHasLocalizationFailed(true);
  });

  useEffect(() => {
    if (isAnalyticsInitialized && hasLocalizationFailed && localizationFile) {
      handleError({
        message: `${i18n.t("Missing localization file")}: ${localizationFile}`,
      });
    }
  }, [isAnalyticsInitialized, handleError, hasLocalizationFailed, localizationFile]);

};

export default useManageLocalizationFailure;
