import axios from "axios";
import jwt_decode from "jwt-decode";

// code challenge functions

function dec2hex(dec) {
  return ("0" + dec.toString(16)).substr(-2);
}

function generateCodeVerifier() {
  var array = new Uint32Array(56 / 2);
  window.crypto.getRandomValues(array);
  return Array.from(array, dec2hex).join("");
}

// GENERATING CODE CHALLENGE FROM VERIFIER
function sha256(plain) {
  // returns promise ArrayBuffer
  const encoder = new TextEncoder();
  const data = encoder.encode(plain);
  return window.crypto.subtle.digest("SHA-256", data);
}

function base64urlencode(a) {
  var str = "";
  var bytes = new Uint8Array(a);
  var len = bytes.byteLength;
  for (var i = 0; i < len; i++) {
    str += String.fromCharCode(bytes[i]);
  }
  return btoa(str).replace(/\+/g, "-").replace(/\//g, "_").replace(/=+$/, "");
}

async function generateCodeChallengeFromVerifier(v) {
  var hashed = await sha256(v);
  var base64encoded = base64urlencode(hashed);
  return base64encoded;
}

export async function startAuthorization(authUrl, ticket, clientId) {
  const codeVerifier = generateCodeVerifier();
  const codeChallenge = await generateCodeChallengeFromVerifier(codeVerifier);

  localStorage.setItem("code-verifier", codeVerifier);

  document.location = `${authUrl}transaction/authorize?&ticket=${ticket}&client_id=${clientId}&state=1656093021&code_challenge_method=S256&code_challenge=${codeChallenge}&claims_redirect_uri=${document.location.href}`;
}

export async function getAccessTokens(authUrl, ticket, clientId, secret) {
  return new Promise((resolve, reject) => {
    axios
      .post(`${authUrl}transaction/token`, {
        grant_type: "urn:ietf:params:oauth:grant-type:uma-ticket",
        ticket: ticket,
        code_verifier: localStorage.getItem("code-verifier"),
        client_id: clientId,
        secret: secret,
      })
      .then((res) => {
        var token = res.data.access_token;
        var decoded = jwt_decode(token);

        resolve(decoded);
      });
  });
}
