import AnalyticsService from "analytics-web";
import { useApp } from "../../context/AppContext";
import { handleAnalyticsTrack } from "../../utils/Analytics";
import { AppError } from "./types/ErrorType";
import ErrorApiFailurePage from "./ui/ErrorApiFailurePage";
import ErrorNoNetworkPage from "./ui/ErrorNoNetworkPage";

interface ErrorFallbackProps {
  error: AppError;
}

function ErrorFallback({
  error: { message, error_code, error_response },
}: ErrorFallbackProps) {
  const { isAnalyticsInitialized, setIsAnalyticsInitialized } = useApp();

  console.log(`message: ${message}, error_code: ${error_code}, error_response: ${error_response}`)

  // Must initialize AnalyticsService early to be able to track and handle any start-up errors in config.
  if (window.config && !isAnalyticsInitialized) {
    AnalyticsService.initialize(window.config.analytics_config.alias, {
      instrumentationKey: window.config.analytics_config.instrumentationKey,
      disableAjaxTracking: true,
    });

    setIsAnalyticsInitialized(true);
  }

  if (window.config?.url) {
    handleAnalyticsTrack(
      error_code || "ERROR_APP_FAILURE",
      error_response?.config.url || "None",
      message
    );
  }

  switch (error_code) {
    case "ERROR_API_FAILURE":
      return <ErrorApiFailurePage />;
    case "ERROR_NO_NETWORK":
      return <ErrorNoNetworkPage />;
    default:
      return <ErrorApiFailurePage message={message} />;
  }
}

export default ErrorFallback;
