/* eslint-disable */

import React, { useState, useEffect } from "react";

// Services
import { useTranslation } from 'react-i18next';
import DynamicContentServices from "../../services/DynamicContentServices";

// Components
import RegularRow from "./RegularRow";
import ScrollableRow from "./ScrollableRow";

// Interfaces
import {
  theme,
  contentRow,
  rowItem,
  selectedLocations,
} from "../../ComponentInterface";
import axios from "axios";

interface Props {
  theme: theme;
  pageContent: contentRow;
  pageName?: string | undefined;
}

const DynamicWidget = ({ pageContent, theme, pageName }: Props) => {
  const [rowContent, setRowContent] = useState(pageContent);
  const [isMounted, setIsMounted] = useState(true);
  const [errorMessage, setErrorMessage] = useState<string>("");
  const [t] = useTranslation("translations");

  // 1. Service to Fetch Dynamic Content

  useEffect(() => {
    const fetchContent = async () => {
      try {
        let items: Array<rowItem> = [];

        const selectedLocations: Array<selectedLocations> = [
          {
            id: "1",
            position: {
              latitude: -79.768482,
              longitude: 43.388983,
            },
            distance: 20,
          },
        ];
        // Burlington
        // const selectedLocations = [{
        //     id: "2",
        //     position: {
        //         "latitude": 43.325989,
        //         "longitude": -79.798302
        //     },
        //     "distance": 20
        // }]
        // Both
        // const selectedLocations = [{
        //     id: "1",
        //     position: {
        //         "latitude": 44.060341,
        //         "longitude": -79.455841
        //     },
        //     "distance": 20
        // }, {
        //     id: "2",
        //     position: {
        //         "latitude": 43.325989,
        //         "longitude": -79.798302
        //     },
        //     "distance": 20
        // }]

        // const tempcontResource = if pageContent.content_resource is URL
        // if yes -- fetch data axios

        let tempContentResource = pageContent.content_resource;

        if (pageContent.content_resource_url) {
          tempContentResource = await new Promise(async (resolve, reject) => {
            await axios.get(pageContent.content_resource).then(function (res) {
              let content = res.data;

              if (pageContent.content_resource_type === "twitter") {
                content = res.data.data;
              }
              resolve(content);
            });
          });
        }

        // 1. Fetch Dynamic Content
        items = await DynamicContentServices.getDynamicContent?.(
          tempContentResource,
          pageContent.content_resource_url
        );

        if (items.length) {
          // 2. Filter Content

          items =
            await DynamicContentServices.filterDynamicContentBasedOnLocation?.(
              items,
              selectedLocations
            );

          const selectionRule = DynamicContentServices.buildSelectionRule(
            pageContent.selectionRule!
          );

          // 3. Filter Content

          items =
            await DynamicContentServices.filterDynamicContentFromUrlBasedonRule?.(
              items,
              selectionRule,
              pageContent.numberOfItemsToShow!
            );

          // 4. Prepare Content Items
          items =
            await DynamicContentServices.handleFormatItemsBasedOnComponentToUse?.(
              items,
              pageContent.ItemComponentToUse!,
              pageContent.ItemComponentSettings
            );
        }

        setRowContent((prevContent: typeof pageContent) => ({
          ...prevContent,
          items,
        }));
        setIsMounted(false);
      } catch (error: any) {
        setIsMounted(false);
        setErrorMessage(t(error));
      }
    };

    fetchContent();
  }, [pageContent, isMounted, t]);

  const renderRowTags = () => {
    // Available type of rows
    const Map = {
      RegularRow,
      ScrollableRow,
    };

    // 1 If rowFormatToUse is available
    if (pageContent.rowFormatToUse) {
      const TagName = Map[pageContent.rowFormatToUse as keyof typeof Map];

      // 1.1 If rowFormatToUse provided is valid
      if (TagName) {
        return (
          <TagName pageContent={rowContent} theme={theme} pageName={pageName} />
        );
      } else {
        // 1.1 If rowFormatToUse provided is not valid error
        return (
          <div className="error">
            {t("DynamicWidget.rowFormatToUse_is_not_valid_error")}
          </div>
        );
      }
    } else {
      // 1.3 If rowFormatToUse provided is not provided error
      return (
        <div className="error">
          {t("DynamicWidget.rowFormatToUse_is_not_valid_error")}
        </div>
      );
    }
  };

  const renderErrors = () => {
    if (errorMessage) {
      return <div className="error">{errorMessage}</div>;
    }

    return;
  };

  return (
    <>
      {/* Type of Row to use Regular or Scrollable */}

      {renderRowTags()}

      {/* Error Messages */}

      {renderErrors()}
    </>
  );
};

export default DynamicWidget;
