import React from 'react'
import { ErrorPage, ErrorIcon, Title, Description, Button } from './ErrorPage.wrapper'
import { useTranslation } from 'react-i18next';


interface ErrorApiFailurePageProps {
  message?: string;

}

const ErrorApiFailurePage = ({message}: ErrorApiFailurePageProps) => {
  const [t] = useTranslation("translations");

  const handleDescriptionText =
    message === undefined
      ? t("Something went wrong on our end. Close and re-open the app, or tap “Try again”.")
      : message;

  return (
    <ErrorPage>
        <ErrorIcon icon_type='exclamation' />
        <Title>{t("Oops! Something went wrong.")}</Title>
        <Description>{handleDescriptionText}</Description>
        {
        <Button>{t("Try Again")}</Button>
        }
    </ErrorPage>
  )
}

export default ErrorApiFailurePage