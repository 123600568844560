import { motion } from 'framer-motion/dist/framer-motion';
import { Dispatch, useState } from "react";
import { SubUserInterests, UserInterests } from "../../Types";
import { InterestActions } from "./reducer";

interface SubInterestCardProps extends SubUserInterests {
    interests: Array<UserInterests>,
    dispatch: Dispatch<InterestActions>,
    currentCategory: UserInterests,
  }
  
  
  const SubInterestCard = ({interests, dispatch, id, title, currentCategory}: SubInterestCardProps) => {
  
    const itemVariants = {
      closed: {
        opacity: 0
      },
      open: { opacity: 1,
      transition: {
        ease: "easeInOut",
        delay: 0.1
      }}
    };
  
  
    const [isSelected, setIsSelected] = useState(currentCategory.subcategories.some((item) => item.id === id))
  
    return (
      <motion.div
        variants={itemVariants}
        animate={{
          transition: { delay: 0, duration: 0.5 }
        }}
        onClick={() => {
         
          if(isSelected){
              dispatch({type: "remove_subcategory", subcategory_id: id, category_id: currentCategory.id})
            } else {
              dispatch({"type": "add_subcategory", subcategory: {id, title}, category_id: currentCategory.id })
            }
    
            setIsSelected(!isSelected)
          }
        }
        className={`subinterestcard ${isSelected ? "subinterestcard--selected" : ""}`}
      >
        <p className="subinterestcard__name">{title}</p>
      </motion.div>
    );
  }

  export default SubInterestCard