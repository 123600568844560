import { importByPageList } from "../StaticWidgets/importPages";
import OnboardingInterests from "./Pages/OnboardingInterests";
import OnboardingIntro from "./Pages/OnboardingIntro";
import OnboardingLocation from "./Pages/OnboardingLocation";
import OnboardingOht from "./Pages/OnboardingOht";

export const OnboardingConfig = () => {
    // dynmically import components if they exist in the config
    
   const order = ["OnboardingIntro", "OnboardingLocation", "OnboardingOht", "OnboardingInterests"];

   const OnboardingMap = new Map([
     [OnboardingIntro.name, OnboardingIntro],
     [OnboardingInterests.name, OnboardingInterests],
     [OnboardingLocation.name, OnboardingLocation],
     [OnboardingOht.name, OnboardingOht],
    ]);

  const List: Array<() => JSX.Element> = order.flatMap((component_name) => {
    if (!OnboardingMap.has(component_name)) return [];
    return OnboardingMap.get(component_name)
  });

  return importByPageList(List[0], List);
};

export default OnboardingConfig