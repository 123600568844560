import { useState } from "react";
import { useErrorHandler } from "react-error-boundary";


const useRequestSpinner = () => {
  const [requestLoading, setRequestLoading] = useState(false);
  const handleError = useErrorHandler();

  /**
   * Custom Hook to handle any axios request with a spinner UI
   * @param AxiosRequest value that returns a promise to be awaited against while showing a spinner
   * @param then a fn (could be anonymous fn) to return whatever you need in a fn block
   */
  const handleRequestWithSpinner = async <T,> (
    AxiosRequest: Promise<T>,
    then: (res?: T) => void
  ) => {
    setRequestLoading(true);

    AxiosRequest.then((res) => {
      setRequestLoading(false);
      then(res);
    }).catch((error) => {
      setRequestLoading(false);

      handleError(error);
    });
  };


  

  return { handleRequestWithSpinner: handleRequestWithSpinner, requestLoading };
};

export default useRequestSpinner;
