import React, { useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import UserService from '../../../services/UserService';
import { ShowBottomBar, ShowTopBar } from "../../../services/ToggleNativeBar";
import Icon from '../../Reusable/Icon';
import StepProgressBar from '../Components/StepProgressBar';
import FindReactNode from '../../../utils/FindReactNode';
import OnboardingConfig from '..';


type OnboardingPageProps = {
    children?: React.ReactNode;
    title?: string;
    nextBtnOnClick?: () => void;
    isNextBtnDisabled?: boolean;
    withSkip?: boolean;
    withBack?: boolean;
}

const OnboardingPage = ({children, nextBtnOnClick, isNextBtnDisabled, title = "", withBack, withSkip}: OnboardingPageProps) => {
  
    const ref = useRef<HTMLDivElement | undefined>();
    const [parentName, setParentName] = useState<string | undefined>();
    const [nextPage, setNextPage] = useState<string | undefined>();

    useEffect(() => {
      ShowTopBar(false)
      ShowBottomBar(false)
    }, [])

   useEffect(() => {
    if (ref.current) {
      let node = FindReactNode(ref.current, 1);
      setParentName(node.type.name)
    }
  }, []);

   useEffect(() => {
    if (parentName) {
      const NextPageIndex = OnboardingConfig().components.findIndex(component => component.Component.name === parentName ) + 1;
      setNextPage("/" + (OnboardingConfig().components[NextPageIndex]?.Component.name || ""));
    }
  }, [parentName]);


    const handleSkipOnboardingOnClick = () => {
      if(nextPage === "/") {
        const prevValue = UserService.getAttribute("userPreferences")
        UserService.setAttribute("userPreferences", {...prevValue, skipOnboarding: true})
      }
    }


  return (
    <>
      <div className="onboarding" ref={ref}>
        <TopBar title={title} skipUrl={withSkip ? nextPage : undefined} skipBtnClick={handleSkipOnboardingOnClick} />
        <div className="onboarding__page">{children}</div>
        <BottomBar
          next={nextPage}
          goBack={withBack}
          stepProgress={OnboardingConfig().components.findIndex((component) => component.Component.name === parentName) + 1}
          nextBtnOnclick={() => {
            handleSkipOnboardingOnClick();
            nextBtnOnClick &&  nextBtnOnClick();
          }}
          isNextBtnDisabled={isNextBtnDisabled}
        />
      </div>
    </>
  );
}

interface TextComponentProps {
  children?: React.ReactNode;
}

export const Title = ({children}: TextComponentProps) => (
  <h1 className="onboarding__title">{children}</h1>
);

export const Description = ({children}: TextComponentProps) => (
  <p className="onboarding__p translatedLineBreak">{children}</p>
);

interface BottombarProps {
    goBack?: boolean,
    next?: string,
    nextBtnOnclick?: () => void,
    isNextBtnDisabled?: boolean,
    stepProgress?: number;
}

export const BottomBar = ({ next, goBack, stepProgress, nextBtnOnclick, isNextBtnDisabled }: BottombarProps) => {
    let history = useNavigate();
    const [t] = useTranslation("translations");
    const handleDisabledStyles = isNextBtnDisabled ? "onboarding__btn--disabled" : "";

    const handleNextBtnClick = () => {
      nextBtnOnclick && nextBtnOnclick();
      next && history(next);
    }
  return (
    <div className='onboarding__bottom_bar'>
    {goBack ? <button onClick={() => { history(-1)}} className="onboarding__btn">
        {t("Onboarding.Back")}
        </button> : <button className='onboarding__btn onboarding__btn--invisible'>{t("Onboarding.Back")}</button> }
    {
      stepProgress && <StepProgressBar step={stepProgress} />
    }
    {next && <button  onClick={() => { handleNextBtnClick()}} className={`onboarding__btn onboarding__next ${handleDisabledStyles}`}  disabled={isNextBtnDisabled}>
    {t("Onboarding.Next")}<Icon iconName="right_arrow" className={`onboarding__btn onboarding__arrow ${handleDisabledStyles}`}/>
        </button> }

    </div>
  );
};

interface TopbarProps {
    title: string,
    skipUrl?: string
    skipBtnClick?: () => void,
}

export const TopBar = ({title, skipUrl, skipBtnClick}: TopbarProps) => {
    let history = useNavigate();
    const [t] = useTranslation("translations");

    const handleSkipBtnClick = () => {
      skipBtnClick && skipBtnClick();
      skipUrl && history(skipUrl);
    }

    return <div className='onboarding__top_bar'>
        <p className='onboarding__top_bar__title'>{title}</p>
       {skipUrl && <button onClick={() => { handleSkipBtnClick()}} className="onboarding__btn">
       {t("Onboarding.Skip")}
        </button> }
    </div>

}


export default OnboardingPage